import Config from "../config";
import { handleTenantRedirect } from "../hooks/useTenantRedirect";
import ExclusionRoutes from "./Enums/ExclusionRoutes";

class SessionManager{
    private static instanceCache?: SessionManager;

    public tokenExpiryWithRememberMe: number = Config.tokenExpiryTime.withRememberMe;
    public tokenExpiryWithoutRememberMe: number = Config.tokenExpiryTime.withoutRememberMe;
    private constructor() {}

    public static instance(): SessionManager {
        if (!this.instanceCache){
            this.instanceCache = new this();
        }
        return this.instanceCache;
    }

    public saveToken(accessToken: string, rememberMe: boolean){
        const now = new Date();
        const expiryTime = now.getTime() + (rememberMe ? this.tokenExpiryWithRememberMe : this.tokenExpiryWithoutRememberMe);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('tokenExpiry', expiryTime.toString());
        localStorage.setItem('rememberMe', rememberMe.toString());
    }

    public getToken() {
        const token = localStorage.getItem('accessToken');
        const expiryString = localStorage.getItem('tokenExpiry');

        if(!token || !expiryString){
            if (!Object.values(ExclusionRoutes).includes(window.location.pathname)) {
                handleTenantRedirect();
            }
            return null
        }

        const expiry = parseInt(expiryString, 10);
        const now = new Date();

        if (now.getTime() > expiry){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiry');
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('username')
            return null;
        }

        return token;
    }

    public saveEmail(email: string) {
        localStorage.setItem('email', email)
    }

    public getEmail() {
        return localStorage.getItem('email')
    }

    static clearSession() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
    }

    public renewSession() {
        const newExpiryTime = Date.now() + (localStorage.getItem('rememberMe') === 'true' 
                ? SessionManager.instance().tokenExpiryWithRememberMe 
                : SessionManager.instance().tokenExpiryWithoutRememberMe);
            
        localStorage.setItem('tokenExpiry', newExpiryTime.toString());
    }
}

export default SessionManager;