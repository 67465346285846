interface RoutePermissions {
    [key: string]: string[];
}
export const routePermissions: RoutePermissions = {
    '/dashboard/sales-pace-report-table': ['sales_pace'],
    '/dashboard/sales-pace-report-line-chart': ['sales_pace_line_chart'],
    '/dashboard/sales-performance-report': ['sales_performance'],
    '/dashboard/sales-performance-report-line-chart': ['sales_performance_chart'],
    '/dashboard/transaction-performance-report': ['transaction_performance'],
    '/dashboard/affiliate-sales-summary': ['affiliate_sales_summary'],
    '/dashboard/cancellationReasons': ['cancellation_reasons'],
    '/dashboard/cancellationSubscriptionReasons': ['cancellation_subscription_reasons'],
    '/dashboard/cancellation-reasons-chart': ['cancellation_reasons_chart'],
    '/dashboard/retention-report': ['retention'],
    '/dashboard/forecasted-rebills-report': ['forecasted_rebills'],
    '/dashboard/forecasted-rebills-chart': ['forecasted_rebills_chart'],
    '/dashboard/conversion-report': ['conversions'],
    '/dashboard/decline-response-report': ['declines'],
    '/dashboard/bin-routing-performance-report': ['bin_routing_performances'],
    '/dashboard/variance-report': ['variances'],
    '/dashboard/card-processing-report': ['card_processings'],
    '/dashboard/duplicate-customer-report': ['duplicate-customer-report'],
    '/dashboard/chargeback-summary-report': ['chargeback_summary'],
    '/dashboard/fraud-analysis-report': ['fraud_analysis'],
    '/dashboard/fulfillment-summary-report': ['fulfillment_summary'],
    '/dashboard/errors-shown-to-customer-report': ['error_logs'],
    '/dashboard/customer-purchase-attempts-report': ['customer_purchase_attempts'],
};

export const actionPermissions = {
    'modify_user_permissions': ['MODIFY_USER_PERMISSIONS']
}
