import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { ErrorsShownCustomerReport, SubCategory } from "./errors-shown-customer.types";

const transformDataToCSVFormat = (
    data: ErrorsShownCustomerReport[],
    namesEnum: { [key: string]: string },
    fieldsForCSV: { [key: string]: string },
): {
    [key: string]: string | number | SubCategory[];
  }[] => {
    if (!data || !data.length) {
        return [];
    }

    const categoryField = fieldsForCSV.grouping;
    const categoryValue =  namesEnum[fieldsForCSV.grouping];
    if (!categoryField) {
        throw new Error(`The grouping field '${fieldsForCSV.grouping}' does not exist in namesEnum.`);
    }

    const flattenedData: {
        [key: string]: string | number | SubCategory[];
      }[] = [];

    data.forEach((report) => {
        const baseData: { [key: string]: string | number | SubCategory[] } = {};
        baseData[categoryField] = capitalizeSpecificWords((report)[categoryValue] as string);
        baseData['Total Count'] = report.count;
        flattenedData.push(baseData);

        report.subCategories.forEach((subCategory) => {
            const subCategoryData: { [key: string]: string | number | SubCategory[] } = {};
            subCategoryData[categoryField] = "";
            subCategoryData['Reason'] = subCategory.reason && subCategory.reason !== "NaN" ? capitalizeSpecificWords(subCategory.reason) : "---";
            subCategoryData['Sub Category Count'] = subCategory.count;
            flattenedData.push(subCategoryData);
        });
    });

    return flattenedData;
}

export default transformDataToCSVFormat;