import getSymbolFromCurrency from 'currency-symbol-map';
import { SalesPaceReportData, SalesPaceReportRowInterface } from '../types/sales-pace-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(
  data: SalesPaceReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined,
  selectedType?: string
) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = ['Currency', ...filters!] 

  function processItem(item: SalesPaceReportRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = (selectedType === 'all' || selectedType === 'sales') ? {
      "Sales": item.total_sales ? item.total_sales?.toString() : '0',
      "Prior Period Sales": item.total_24_sales ? item.total_24_sales?.toLocaleString() : '0',
      "Revenue": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_revenue ? item.total_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Prior Period Rev": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_24_revenue ? item.total_24_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "AOV": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_aov ? item.total_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Prior Period AOV": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_24_aov ? item.total_24_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Revenue Change %": (item.total_24_revenue && item.total_24_revenue !== 0)
        ? parseFloat((((item.total_revenue || 0) / item.total_24_revenue) * 100).toString()).toFixed(2) + ' %'
        : '---',
    } : {};

    const steps = ["s1", "s2"]; 
    steps.forEach((step, index) => {
      const stat = item;
      const prefix = step + '_';
      
      if (stat && selectedType !== 'subscriptions') {
        baseItem[`Step ${index + 1} Sales`] = stat[`${prefix}sales`] ? stat[`${prefix}sales`]?.toLocaleString() : '0';
        baseItem[`Step ${index + 1} Prior Period Sales`] = stat[`${prefix}24_sales`] ? stat[`${prefix}24_sales`]?.toLocaleString() : '0';
        baseItem[`Step ${index + 1} Revenue`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '')  +  (stat[`${prefix}revenue`] ? stat[`${prefix}revenue`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Prior Period Rev`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}24_revenue`] ? stat[`${prefix}24_revenue`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} AOV`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}aov`] ? stat[`${prefix}aov`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Prior Period AOV`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}24_aov`] ? stat[`${prefix}24_aov`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Revenue Change %`] = stat[`${prefix}24_percentage`] ? parseFloat(stat[`${prefix}24_percentage`]?.toString()).toFixed(2)  + ' %' : '---';
      }
    });

    // add subscriptions
    if (selectedType !== 'sales') {
      baseItem['Subscriptions'] = item.rec_sales ? item.rec_sales?.toLocaleString() : '0';
      baseItem['Subscriptions Prior Period Sales'] = item.rec_24_sales ? item.rec_24_sales?.toLocaleString() : '0';
      baseItem['Subscriptions Revenue'] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.rec_revenue ? item.rec_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
      baseItem['Subscriptions Prior Period Rev'] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.rec_24_revenue ? item.rec_24_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
      baseItem['Subscriptions AOV'] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.rec_aov ? item.rec_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
      baseItem['Subscriptions Prior Period AOV'] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.rec_24_aov ? item.rec_24_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
      baseItem['Subscriptions Revenue Change %'] = item.rec_24_percentage ? parseFloat(item.rec_24_percentage?.toString()).toFixed(2) + ' %' : '---';
    }

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

    baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        const sortedItems = item[nestedKey].sort((a: any, b: any) => (b.total_sales || 0) - (a.total_sales || 0));
        sortedItems.forEach((nestedItem: SalesPaceReportRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    data.currency.sort((a: any, b: any) => (b.total_sales || 0) - (a.total_sales || 0));
    // @ts-ignore
    data.currency.forEach((currencyItem) => processItem(currencyItem));
  }

  const groupingFields = Array.from(groupingLevels).map((level) => {
    const foundKey = getKeyByValue(namesEnum, filtersList[level]);
    return foundKey ?? filtersList[level];
  });

  return result.map((item) => {
    const transformedItem: { [key: string]: string } = {};

    // Ensure all required fields are included in the transformed item
    groupingFields.forEach((field) => {
      if (field === 'Currency' && item[field]) {
        transformedItem[field] = `${item[field]?.toUpperCase()} - ${getSymbolFromCurrency(item[field])}`;
      } else {
        transformedItem[field] = item[field] || '';
      }
    });

    // Map fields for CSV based on fieldsForCSV object
    if (selectedType !== 'subscriptions') {
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[key] || '';
      });
    }

    // Add steps data to the final transformed item
    if (selectedType !== 'subscriptions') {
    const steps = ["s1", "s2"];
    steps.forEach((step, index) => {
        transformedItem[`Step ${index + 1} Sales`] = item[`Step ${index + 1} Sales`] || '';
        transformedItem[`Step ${index + 1} Prior Period Sales`] = item[`Step ${index + 1} Prior Period Sales`] || '';
        transformedItem[`Step ${index + 1} Revenue`] = item[`Step ${index + 1} Revenue`] || '';
        transformedItem[`Step ${index + 1} Prior Period Rev`] = item[`Step ${index + 1} Prior Period Rev`] || '';
        transformedItem[`Step ${index + 1} AOV`] = item[`Step ${index + 1} AOV`] || '';
        transformedItem[`Step ${index + 1} Prior Period AOV`] = item[`Step ${index + 1} Prior Period AOV`] || '';
        transformedItem[`Step ${index + 1} Revenue Change %`] = item[`Step ${index + 1} Revenue Change %`] || '';
      });
    }

    // add subscriptions
    if (selectedType !== 'sales') {
      transformedItem['Subscriptions'] = item['Subscriptions'] || '';
      transformedItem['Subscriptions Prior Period Sales'] = item['Subscriptions Prior Period Sales'] || '';
      transformedItem['Subscriptions Revenue'] = item['Subscriptions Revenue'] || '';
      transformedItem['Subscriptions Prior Period Rev'] = item['Subscriptions Prior Period Rev'] || '';
      transformedItem['Subscriptions AOV'] = item['Subscriptions AOV'] || '';
      transformedItem['Subscriptions Prior Period AOV'] = item['Subscriptions Prior Period AOV'] || '';
      transformedItem['Subscriptions Revenue Change %'] = item['Subscriptions Revenue Change %'] || '';
    }

    return transformedItem;
  });
}

export default transformDataToCSVFormat;
