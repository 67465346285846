import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/outline";
import {tableCellClasses} from "@mui/material/TableCell";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import {Icon} from "@tremor/react";
import Utils from "../../../../odinForgeService/Utils";
import TableLoading from "../../../UI/loading/TableLoading";
import '../../../../css/PaceReport.css';
import {borderColorEnum, darkBorderColorEnum, darkMainColorEnum, darkSubColorEnum, mainColorEnum, subColorEnum} from "../../../../types/enums/TableColors"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DonutChartHero } from "./components/PieChart";
import FilterBar from "../../../organism/filterbar/FilterBar";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import ScrollContainer from "react-indiana-drag-scroll";
import { extractDates, transformData } from "./utils/transformData";
import { reorderLevels, updateExpandedState, determineNestingLevels, handleExpand, handleMainExpand } from "../../../../helpers/collapsingFunctions";
import { dateRangeList, fieldsForCSV, generateRangeConfigs, namesEnum, rangeConfigs } from "./utils/config";
import { rowBottomBorder } from "../../../../constants/rowBottomBorder";
import { sortDataForRebillsReport } from "../../../../helpers/sortData";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import SortedArrowAscending from "../../../../assets/icons/SortedArrowAscending";
import SortedArrowDescending from "../../../../assets/icons/SortedArrowDescending";
import { getRowHoverColor } from "../../../../helpers/rowHoverColor";
import ReactCountryFlag from "react-country-flag"
import { hasFlag } from 'country-flag-icons'
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import formatRowName from "../../../../helpers/formatRowName";
import { ForecastedRebillsReportChartData, ForecastedRebillsReportClonedData, ForecastedRebillsReportData, ForecastedRebillsRowInterface, NestedLevels } from "./types/forecasted-rebills-report.types";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { columnWidth, getFirsrColimnWidth, getLoadMoreBtnWidth } from "../../../../helpers/getFirstColumnWidth";
import LoadMoreButton from "../../../UI/LoadMoreButton/LoadMoreButton";
import { searchRowForecasedRebills } from "../../../../helpers/searchRow";
import highlightMatch from "../../../../helpers/highlightMatch";
import { DataRangesParamsList } from "../../../../types/enums/DataRangesParamsList";

dayjs.extend(utc);
dayjs.extend(timezone);

interface props {
    names: string[]
    clonedData: ForecastedRebillsReportClonedData
    passData: (data: ForecastedRebillsReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}
const ForecastedRebills: React.FC<props> = ({
                                        names,
                                        clonedData,
                                        passData,
                                        passId,
                                        setRefreshKey,
                                        refreshKey,
                                    }: props) => {
    const { themedColors, theme } = useContext(ThemeContext)!;
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings, setSelectedGroupings] = React.useState(3)
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'0');
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const params = new URLSearchParams();
    const [showTable, setShowTable] = React.useState(!!clonedData['data'])
    const [data, setData] = React.useState<ForecastedRebillsReportData | []>(clonedData['data']?clonedData['data']:[]);
    const expandLevel = collapse;
    const reportName = ReportName.ForecastedRebillReport;
    const groupSequence =  groupings;
    const reportConfigValues = useRef({ expandLevel, reportName, groupSequence });
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
    const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const [previousEndDate, setPreviousEndDate] = useState(endDate);
    const [tableHeadings, setTableHeadings] = useState<React.JSX.Element[]>([]);

    const isMobile = useMediaQuery('(max-width: 1023px)')

    let transformedGroupings = ['Currency', ...groupings];

    const [isChartLoader, setIsChartLoader] = useState(false);
    const [chartData, setChartData] = useState<ForecastedRebillsReportChartData[]>([]);
    const [isChartData, setIsChartData] = useState(false);

    const [datesHeading, setDatesHeading] = useState<string[]>([]);

    const colSpanCount = 2 + datesHeading.length;

    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});
    const [ascending, setAscending] = useState<null | boolean>(null);
    const [sortField, setSortField] = useState<string | null>(null);

    const [isHoveredRow, setIsHoveredRow] = useState<number | null | string>(null);

    const [ chartFilter, setChartFilter ] = useState(groupings[0] ?? '');

    const [ dataSize, setDataSize] = useState<number>(0)

    const isInitialLoadCollapse = useRef(true);

    const isInitialLoad = useRef(true);

    const [page, setPage] = useState({
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
    });
    const [loaderKey, setLoaderKey] = useState(0);

    const [ isSearchResult, setIsSearchResult ] = useState(true);
    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
    const [filteredData, setFilteredData] = useState(data);

    const [csvFilters, setCsvFilters] = useState<string[]>([])

    useEffect(() => {
        if (data && collapse !== undefined) {
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    useEffect(() => {
        if (dateRange !== 0) handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
        }    },[startDate,endDate])

    function newTable(row: ForecastedRebillsRowInterface, groupIndex: number, parents: string[]) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetTableBody(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1, parents)
        }
        else
            return  null;
    }

    function newTableTotal(row: ForecastedRebillsRowInterface, groupIndex: number, parents: string[]) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetTableBodyTotal(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1, parents)
        }
        else
            return  null;
    }

    function GetSubTable(row: ForecastedRebillsRowInterface, index: number, borderColor: string, groupIndex: number, parents: string[], rowCount: number) {
        const staticParents = parents.slice()
        staticParents.push(row.name)

        const classNameBorderBottom = rowBottomBorder
        let isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        const isHidden = index + 1 > page[groupIndex as keyof typeof page]*10


        if (isHidden) {
            return null
        }

        const isLastRow = rowCount === (index + 1)

        const tableCells = generateData(row);

        function generateData(row: ForecastedRebillsRowInterface){
            return datesHeading.map((label) => {
                return (
                    (
                        <TableCell key={label} style={{fontSize: '12px', padding: '0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme)}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>
                            {row?.stats && row?.stats[label] ? row?.stats[label]?.formatted : "-"}
                        </TableCell>
                    )
                )
            });
        }

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow style={{ padding:0, height:42}}>
                        <TableCell style={{fontSize:'12px',padding:'0px 8px 0px 0px', width:(columnWidth as { [key: number]: number })[selectedGroupings] - (150 - (dataSize * 1)), backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme)}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>
                        </TableCell>

                        {tableCells}
                        <TableCell align={'right'} style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', textAlign: `${isMobile && showTable ? 'left' : showTable ? 'right' : 'center'}`, borderLeft: isMobile ? `1px solid ${theme === 'light' ? "#ACBFDF" : '#495D80'}` : 'none', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme)}} className={`${classNameBorderBottom} safari-align-left`}  onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>{row?.stats?.total?.formatted}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={colSpanCount} style={{padding:0}}>
                            <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>

                                <Table style={{padding:0}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newTable(row, groupIndex, staticParents)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
                {!isLastRow && <TableRow 
                    className={"h-[42px] flex w-0"}
                    style={{
                        display: (index + 1 === page[groupIndex as keyof typeof page]*10) ? 'table' : 'none',
                    }}
                ></TableRow>}
            </Table>
        )
    }

    function GetTableBody(temp1:ForecastedRebillsRowInterface[], groupIndex: number, parents: string[]) {
        const staticData:ForecastedRebillsRowInterface[] = temp1;
        const color = theme === 'light' ? borderColorEnum[groupIndex] : darkBorderColorEnum[groupIndex]
        const name = transformedGroupings[groupIndex]
        const isExpanded = nestedLevels[namesEnum[name]]?.expanded

        const pageSize = 10;
        const currentPage = page[groupIndex as keyof typeof page];
        const endIndex = currentPage * pageSize;
        const visibleData = staticData && staticData.slice(0, endIndex);

        return <>
            <TableBody>
            <TableRow style={{fontSize:'12px', backgroundColor:  theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5}}>
                    <TableCell align={'left'} colSpan={colSpanCount} style={{padding:0}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {handleMainExpand(namesEnum[name], setNestedLevels)
                            }}
                        >
                            {isExpanded ? <Icon icon={ChevronDownIcon} style={{color:'white'}}/> : <Icon icon={ChevronRightIcon} style={{color:'white'}}/>}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-bold'}>{name}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell colSpan={colSpanCount} style={{padding:0}}>
                        <Collapse in={
                            isExpanded
                        } timeout={'auto'} unmountOnExit>
                            {visibleData?.map((row:ForecastedRebillsRowInterface, index:number) => (
                                GetSubTable(row,index,color,groupIndex, parents, temp1.length)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    function GetSubTableTotal(row: ForecastedRebillsRowInterface,index: number, borderColor: string, groupIndex: number, parents: string[], rowCount: number) {
        const staticParents = parents.slice()

        const classNameBorderBottom = rowBottomBorder
        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        const isHidden = index + 1 > page[groupIndex as keyof typeof page]*10

        if (isHidden) {
            return null
        }

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow style={{padding: 0, height: 42}}>
                        <TableCell
                            style={{
                                fontSize: '12px', padding: '0px 0px 0px 0px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), borderTopLeftRadius: 0
                            }}  className={`${classNameBorderBottom}`}
                            onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}
                            >
                            <div className={'flex flex-row flex-nowrap text-nowrap'}
                                 style={{borderLeft: `1px solid ${theme === 'light' ? "#ACBFDF" : '#495D80'}`, height: 42, padding: '12px 0px 0px 8px'}}>
                                {row?.stats?.total?.formatted}
                            </div>  </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{padding:0}}>
                            <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>

                                <Table style={{padding:0}} sx={{

                                }}>
                                    {newTableTotal(row, groupIndex, staticParents)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function GetTableBodyTotal(temp1:ForecastedRebillsRowInterface[], groupIndex: number, parents: string[]) {
        const staticData:ForecastedRebillsRowInterface[] = temp1;
        const color = theme === 'light' ? borderColorEnum[groupIndex] : darkBorderColorEnum[groupIndex]
        const name = transformedGroupings[groupIndex]
        const isExpanded = nestedLevels[namesEnum[name]]?.expanded

        const pageSize = 10;
        const currentPage = page[groupIndex as keyof typeof page];
        const endIndex = currentPage * pageSize;
        const visibleData = staticData && staticData.slice(0, endIndex);

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px', backgroundColor:  theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5, height:'42px'}}>
                    <TableCell   style={{padding:0}}>

                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell  style={{padding:0}}>
                        <Collapse in={
                            isExpanded
                        } timeout={'auto'} unmountOnExit>
                            {visibleData?.map((row:ForecastedRebillsRowInterface, index:number) => (
                                GetSubTableTotal(row,index,color,groupIndex, parents, temp1.length)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    function newFirstColumn(row: ForecastedRebillsRowInterface, groupIndex: number) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetFirstColumn(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1)
        }
        else
            return  null;
    }
    
    function GetFirstColumnNames(row: ForecastedRebillsRowInterface, index: number, borderColor: string, groupIndex: number, rowCount: number) {
        let classNameBorderBottom = ``

        let isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        let isNotNeededBorder = groupIndex === selectedGroupings - 1 ? true : false

        if (isNotNeededBorder) {
            if (isExpanded) {
                classNameBorderBottom = rowBottomBorder
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        } else {
            if (isExpanded) {
                classNameBorderBottom = ''
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        }

        const isHidden = index + 1 > page[groupIndex as keyof typeof page]*10

        if (isHidden) {
            return null
        }

        const isLastRow = rowCount === (index + 1)

        const cellWidth = getFirsrColimnWidth(selectedGroupings, groupIndex);
        const charCount = Math.floor(Number(cellWidth.replace('px', ''))! / 7);
        const rowName = formatRowName(row.name, row.currency, groupIndex)

        const loadMoreBrnContainerWidth = getLoadMoreBtnWidth(selectedGroupings, groupIndex);

        function getIsTooltop() {
            return rowName?.length > charCount  ? rowName : '';
        }

        return (
            <Table key={index} >
                <TableBody>
                    <TableRow style={{ padding:0}}>
                    <Tooltip 
                            title={ getIsTooltop()}
                            placement={isMobile ? "bottom-start" : "left"}
                            classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                            arrow
                            enterTouchDelay={10}
                            leaveTouchDelay={1000}
                        >
                            <TableCell style={{cursor: groupIndex !== transformedGroupings.length - 1 ? 'pointer' : 'default', fontSize:'12px',padding:'0px 0px 0px 0px', width:200, borderTopLeftRadius: 0, backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme),}} className={classNameBorderBottom} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>
                                <div className={'flex flex-row flex-nowrap text-nowrap'}
                                    onClick={() => {
                                        handleExpand(row?.id, groupIndex + 1, setNestedLevels)
                                    }}
                                >
                                    {
                                        groupIndex !== transformedGroupings.length - 1 ? (
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                disabled={true}
                                            >
                                                {isExpanded ? <Icon icon={ChevronDownIcon} className={"!text-black dark:!text-odin-dark-content"}/> : 
                                                    <Icon icon={ChevronRightIcon} className={"!text-black dark:!text-odin-dark-content"}/>}
                                                    </IconButton>
                                        ) : (
                                            <span className="h-[42px] w-2" >
                                            </span>
                                        )
                                    }
                                    <span className={hasFlag(row.name)?'pt-3 pr-3':'pt-3 hidden'}>
                                        {
                                            hasFlag(row.name) ? <ReactCountryFlag countryCode={row.name} svg style={{ width: '18px', height: '16px', }} /> : null
                                        } 
                                    </span>
                                    <span
                                        style={{
                                            width: cellWidth,
                                            fontSize: '12px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                        className={'pt-[15px] leading-3 dark:text-odin-dark-content'}
                                        >
                                        {
                                            highlightMatch(rowName, searchTerm, theme)
                                        }
                                    </span>
                                </div>
                            </TableCell>
                        </Tooltip>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{padding:0}}>
                            <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>
                                <Table style={{padding:0,marginLeft:36, minWidth:150}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newFirstColumn(row, groupIndex)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
                <div style={{width: loadMoreBrnContainerWidth}}>
                    {!isLastRow && <LoadMoreButton groupIndex={groupIndex} index={index} page={page} setPage={setPage} isHoveredRow={isHoveredRow} setIsHoveredRow={setIsHoveredRow} />}
                </div>
            </Table>
        )
    }

    function GetFirstColumn(temp1:ForecastedRebillsRowInterface[], groupIndex: number) {
        const data:ForecastedRebillsRowInterface[] = temp1;
        const color = theme === 'light' ? borderColorEnum[groupIndex] : darkBorderColorEnum[groupIndex]
        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.expanded

        const pageSize = 10;
        const currentPage = page[groupIndex as keyof typeof page];
        const endIndex = currentPage * pageSize;
        const visibleData = data && data.slice(0, endIndex);

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px',  backgroundColor: theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5}}>
                    <TableCell align={'left'} style={{padding:0, cursor: groupIndex !== 0 ? 'pointer' : 'default'}}
                        onClick={groupIndex !== 0 ? () => {handleMainExpand(namesEnum[transformedGroupings[groupIndex]], setNestedLevels)}: () => {}}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={true}
                        >
                            {groupIndex !== 0 ? isExpanded ? <Icon icon={ChevronDownIcon} className={"!text-white dark:text-odin-dark-content"}/> : <Icon icon={ChevronRightIcon} className={"!text-white dark:text-odin-dark-content"}/> : <div className="h-8" />}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-semibold'} id={transformedGroupings[groupIndex]}>{transformedGroupings[groupIndex]}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell style={{padding:0}}>
                        <Collapse in={
                            isExpanded
                        } timeout={'auto'} unmountOnExit>
                            {visibleData?.map((row:ForecastedRebillsRowInterface, index:number) => (
                                GetFirstColumnNames(row,index,color,groupIndex, temp1.length)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');
        
        const valuesChanged = (
            collapse !== clonedData['expand_level']
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { expandLevel, reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.collapse !== collapse ||
            previousValues.dateRange !== dateRange
        )) {
            handleBlur();
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                collapse: collapse,
                dateRange: dateRange
            };
        }
        
        // setSearchTerm('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate]);

    useEffect(() => {
        if (dateRange === 0) {
            handleBlur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings]);

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            collapse: collapse,
            dateRange: dateRange
        };
    }, [groupings, collapse, reportName, dateRange, theme]); 

    function generateParams() {
        if (dateRange === 0) {
            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        } else {
            const { startDate, endDate } = (generateRangeConfigs(timezone))[dateRange];

            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        }
        
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })
        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

    }   

    function generateParamsWithFirstFilter(filter: string) {
        if (!filter || filter === "") {
            return []
        }
        const params = new URLSearchParams();
    
        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
    
        if (groupings.length > 0 && (namesEnum)[groupings[0]]) {
            params.append(`filters[0]`, (namesEnum)[filter])
        }
    
        params.append('timezone', timezone) 
        
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

        return params;
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
        }

        return dataToHash;
    }

    const isDataSame = (groupings: string[], previousGroupings: string[], startDate: string | Dayjs, previousStartDate: string | Dayjs, superTenant: string | null = null, previousSuperTenant: string | null = null) => {
        if (!isSuperAdmin) {
            return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate);
        }
        return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && superTenant === previousSuperTenant);
    };

    useEffect(() => {
        if (!isInitialLoad.current) {
            handleBlur();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    async function handleBlur(isRefresh?: boolean) {
        setShowTable(false);
        setTableLoading(true);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        if (isRefresh) {} else {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
        generateParams()
        if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant) || isRefresh) {
            setPage({
                0: 1,
                1: 1,
                2: 1,
                3: 1,
                4: 1,
                5: 1,
            })
            setData([]);
            const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
            if (cachedData){
                setTableLoading(false);
                const transformedData = await transformData(cachedData.currency);
                setData(transformedData)
                const dates = extractDates(transformedData);
                setDatesHeading(dates)
                const tableHeadings = generateHeadings(dates, transformedData);
                setTableHeadings(tableHeadings);
                setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))
                setSelectedGroupings(transformedGroupings.length);
                if (Number(collapse)>transformedGroupings.length){
                    setCollapse((transformedGroupings.length).toString())
                }
                setShowTable(true);
                setChartFilter(groupings[0])

                // It is for expanding the table to the last level
                if (!isInitialLoadCollapse.current) {
                    setCollapse(transformedGroupings.length.toString());
                } else {
                    isInitialLoadCollapse.current = false;
                }
            } else {
                OdinForgeService.instance().getForecastedRebillsReport(params, clonedReportId)
                    .then(async (result: {data: ForecastedRebillsReportData, cacheTime: number}) => {
                        if (Object.keys(result.data).length === 0) {
                            const tableHeadings = generateHeadings([], []);
                            setTableHeadings(tableHeadings);
                            
                            setData([]);
                            setIsChartData(false)
                            // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                            setTableLoading(false);
                            setShowTable(false);
                            return;
                        }
                        setTableLoading(false);
                        const transformedData = await transformData(result.data.currency);
                        setData(transformedData);
                        const dates = extractDates(transformedData);
                        if (dates.length) setDataSize(dates.length)
                        setDatesHeading(dates)                          
                        const tableHeadings = generateHeadings(dates, transformedData);
                        setTableHeadings(tableHeadings);
                        setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))
                        setSelectedGroupings(transformedGroupings.length);
                        if (Number(collapse)>transformedGroupings.length){
                            setCollapse((transformedGroupings.length).toString())
                        }
                        setShowTable(true);
                        setChartFilter(groupings[0])

                        createHash(generateObjectToHash, result.data, reportName, result.cacheTime)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName, result.cacheTime)

                        // It is for expanding the table to the last level
                        if (!isInitialLoadCollapse.current) {
                            setCollapse(transformedGroupings.length.toString());
                        } else {
                            isInitialLoadCollapse.current = false;
                        }

                        if (isRefresh) getChartData()
                    })
                    .catch((error) => {
                        if (error.code === 'ERR_CANCELED') {
                            setLoaderKey(prevKey => prevKey + 1);
                            console.log('Request canceled');
                        } else {
                            setTableLoading(false);
                            Utils.instance().onFailure('An error occurred while fetching the report');
                        }
                    });
            }
        } else {
            if (data.length!==0)
                setShowTable(true)
            setTableLoading(false);
        }
        setPreviousGroupings(groupings);
        setPreviousStartDate(startDate)
        setPreviousEndDate(endDate)
        setPreviousSuperTenant(superTenant);
    }

    async function getChartData() {
        setIsChartLoader(true);
        const paramsForLineChartgenerateParams = generateParamsWithFirstFilter(chartFilter)
        // const cachedData = Utils.instance().checkHash(generateObjectToHash(), `${reportName}-chart`)
          OdinForgeService.instance().getForecastedRebillsChart(paramsForLineChartgenerateParams, `${clonedReportId}-chart`)
          .then(async (result) => {
              setChartData(result.data);
              setIsChartData(true)
              setIsChartLoader(false);

              createHash(generateObjectToHash, result.data, `${reportName}-chart`, result.cacheTime)
            //   Utils.instance().createHash(generateObjectToHash(), result.data, `${reportName}-chart`, result.cacheTime)
              
          })
          .catch((error) => {
            if (error.code === 'ERR_CANCELED') {
                console.log('Request canceled');
            } else {
                Utils.instance().onFailure('An error occurred while fetching the chart data');
            }
        });
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            expand_level: collapse,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
        }
        passData(dataToPass)
    }
   
    function generateHeadings(dates: string[], transformedData: ForecastedRebillsReportData | []) {
        const headings: React.JSX.Element[] = [];
    
        dates.forEach((date: string) => {
            const tableCell = (
                <TableCell 
                    style={{fontSize: '12px', width: '120px', padding: '16px 8px 16px 8px', color: themedColors.gray.bluish}} 
                    className={` ${sortField === date && (ascending !== null ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}             
                >  
                    <span
                        className={`text-[#677C9F] ${'cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7]'} flex gap-2 items-center`} 
                        onClick={() => sortTableData(transformedData, 'rebills', date)}
                    >   
                        {date} {sortField === date && (ascending === false ? <SortedArrowAscending /> : ascending === true ? <SortedArrowDescending /> : '')} 
                    </span>
                </TableCell>
            );
    
            headings.push(tableCell);
        });

        return headings;
    }

    const sortTableData = useCallback( (data: ForecastedRebillsReportData | [], field: string, date: string) => {
        if (!data || data.length === 0) return;
        setAscending(prevAscending => {
            setSortField(date);
            const nextAscending = !prevAscending;
            const sortedData = sortDataForRebillsReport(data, date, nextAscending, namesEnum);
            setData(sortedData);
            return nextAscending;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setData, namesEnum]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableHeadings1 = React.useMemo(() => generateHeadings(datesHeading, data), [datesHeading, data, sortField, ascending]);

    useEffect(() => {
        setTableHeadings(tableHeadings1);
    }, [tableHeadings1]);

    useEffect(() => {
        getChartData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartFilter, dateRange, startDate, endDate, superTenant]);

    const handleSearch = (isReset = false) => {
        if (searchTerm !== '') localStorage.setItem('searchTerm', searchTerm);

        if (!isReset) {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }

        setIsSearchResult(true)
        setShowTable(true)
        setPage({
            0: 1,
            1: 1,
            2: 1,
            3: 1,
            4: 1,
            5: 1,
        })

        handleBlur(true)
    };

    useEffect(() => {
        const searchString = searchTerm ?? ''

        if (searchString) {
            const result = searchRowForecasedRebills(data,searchString);

            if (!result) {
                setShowTable(false);
                setIsSearchResult(false)
            }

            setFilteredData(result);
        } else {
            setFilteredData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setCsvFilters(Array.from(new Set([
            ...groupings.map((grouping) => `${namesEnum[grouping]}`),
            ...(searchTerm ? [`search-${searchTerm}`] : []), 
        ])))
    }, [groupings, searchTerm])

    return (
        <div className={"pb-10"}>
            <div className="!p-4 tablet-size:!p-0">
                <FilterBar
                    handleBlur={handleBlur}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    groupings={groupings}
                    setGroupings={setGroupings}
                    collapse={collapse}
                    setCollapse={setCollapse}
                    selectedGroupings={selectedGroupings}
                    copyButton={copyButton}
                    freezeButton={freezeButton}
                    showDelete={showDelete}
                    clonedReportId={clonedReportId}
                    setDeletePopup={setDeletePopup}
                    passId={passId}
                    deletePopup={deletePopup}
                    setFreezeButton={setFreezeButton}
                    setCopyButton={setCopyButton}
                    clonedData={clonedData} 
                    generateConfigRequestBody={generateConfigRequestBody}
                    generateClonedData={generateClonedData}
                    names={names}
                    title={ReportName.ForecastedRebillReport}
                    isAdditionalCurrencyField={true}
                    transformedGroupings={transformedGroupings}
                    maxSelections={5}
                    // for CSV btn
                    data={data}
                    fieldsForCSV={fieldsForCSV}
                    disabledCSV={!showTable}
                    namesEnum={namesEnum}
                    isCSV={true}
                    transformDataToCSVFormat={transformDataToCSVFormat}
                    csvFilters={csvFilters}
                    isCustomFilters={true}
                    // For datepicker
                    disableFutureDates={false}
                    rangeConfigs={rangeConfigs}
                    dateRangeList={dateRangeList}
                    generateRangeConfigs={generateRangeConfigs}
                    // Refresh cloneData
                    setRefreshKey={setRefreshKey}
                    refreshKey={refreshKey}
                    // SearchRowProps
                    isSearch={true}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleSearch={handleSearch}
                />
            </div>
            <ScrollContainer className="scroll-container" hideScrollbars={false} mouseScroll={{rubberBand: false}} style={{cursor: 'default'}}>
            <div className={`pt-0 tablet-size:pt-6  rounded-3xl ${isMobile && 'relative'} safari-relative`} >
                <div className={'absolute left-0 tablet-size:left-6 z-10 overflow-hidden shadow-[4px_0_8px_-5px_rgba(0,0,0,0.25)] safari-left-zero'} style={{width:(columnWidth as { [key: number]: number })[selectedGroupings]}}>
                    {showTable && filteredData ?
                        (<>
                            <TableContainer style={{overflowX: 'hidden'}}>
                                <Table sx={{
                                    "& .MuiTableRow-root th:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                    "& .MuiTableRow-root th:last-of-type": {
                                        borderTopRightRadius: "10px",
                                    },
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    }
                                }}>
                                    <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"}>
                                        <TableRow>
                                            <TableCell style={{
                                                fontSize: '12px',
                                                padding: '16px 8px 16px 8px',
                                                position: 'sticky',
                                                color: themedColors.gray.bluish
                                            }}>Groupings</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {GetFirstColumn((filteredData as ForecastedRebillsRowInterface)[(namesEnum)[transformedGroupings[0]]], 0)}
                                </Table>
                            </TableContainer>
                        </>)
                        : null}
                </div>

                <div className={`absolute right-[23px] z-10 overflow-hidden w-[120px] tablet-size:w-[250px] ${isMobile ? 'hidden' : ''} safari-hidden`}
                >
                    {showTable && filteredData ?
                        (<>
                            <TableContainer style={{overflowX: 'hidden'}}>
                                <Table sx={{
                                    "& .MuiTableRow-root th:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                    "& .MuiTableRow-root th:last-of-type": {
                                        borderTopRightRadius: "10px",
                                    },
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    }
                                }}>
                                    <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"}>
                                        <TableRow>
                                            <TableCell style={{
                                                fontSize: '12px',
                                                padding: '16px 8px 16px 8px',
                                                position: 'sticky',
                                                borderLeft: `1px solid ${theme === 'light' ? "#ACBFDF" : '#495D80'}`,
                                                color: themedColors.gray.bluish,
                                                borderTopLeftRadius: 0
                                            }}
                                                className={` ${sortField === 'total' && (ascending !== null ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}
                                            >
                                                <span
                                                    className={`text-[#677C9F] cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7] flex gap-2 items-center`} 
                                                    onClick={() => sortTableData(data, 'rebills', 'total')}
                                                >
                                                    Total {sortField === 'total' && (ascending === false ? <SortedArrowAscending /> : ascending === true ? <SortedArrowDescending /> : '')} 
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {GetTableBodyTotal((filteredData as ForecastedRebillsRowInterface)[(namesEnum)[transformedGroupings[0 % selectedGroupings]]], 0, [])}
                                </Table>
                            </TableContainer>
                        </>)
                        : null}
                </div>


                <TableContainer className={''} style={{
                    minWidth: (showTable ? 1000 + (tableHeadings.length * 320) : 0),
                    width: selectedGroupings > 5 ? '120%' : '100%',
                    overflow: 'hidden'
                }}>
                    <Table className={''} sx={{
                        "& .MuiTableRow-root th:first-of-type": {
                            borderTopLeftRadius: "10px",
                        },
                        "& .MuiTableRow-root th:last-of-type": {
                            borderTopRightRadius: "10px",
                        },
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}>
                        <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]"} style={{borderRadius: 5}}>
                            <TableRow>
                                <TableCell style={{
                                    fontSize: '12px',
                                    height: '52px',
                                    width: (columnWidth as { [key: number]: number })[selectedGroupings] - (150 - (dataSize * 1)),
                                    padding: '16px 8px 16px 8px',
                                    color: themedColors.gray.bluish
                                }}>{showTable ? 'Groupings' : ''}</TableCell>

                                {tableHeadings}

                                <TableCell align={'right'} style={{
                                    fontSize: '12px',
                                    width: '120px',
                                    padding: '16px 8px 16px 8px',
                                    textAlign: `${isMobile && showTable ? 'left' : showTable ? 'right' : 'center'}`,
                                    borderLeft: isMobile ? `1px solid ${theme === 'light' ? "#ACBFDF" : '#495D80'}` : 'none',
                                    color: themedColors.gray.bluish
                                }}
                                className={`safari-align-left ${isMobile && sortField === 'total' && (ascending !== null ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}
                                >
                                    <span
                                        className={`safari-align-left text-[#677C9F] cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7] flex gap-2 items-center`} 
                                        onClick={() => sortTableData(data, 'rebills', 'total')}
                                        style={{
                                            justifyContent: `${isMobile && showTable ? 'left' : showTable ? 'right' : 'center'}`,
                                        }}
                                    >
                                        {showTable ? 'Total' : ''} <span className="block tablet-size:hidden">{sortField === 'total' && (ascending === false ? <SortedArrowAscending /> : ascending === true ? <SortedArrowDescending /> : '')}</span>
                                    </span>
                                    
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        {showTable && filteredData ?
                            (<>
                                {GetTableBody((filteredData as ForecastedRebillsRowInterface)[(namesEnum)[transformedGroupings[0 % selectedGroupings]]], 0, [])}
                            </>)
                            : null}
                    </Table>
                </TableContainer>
                {!showTable ? 
                    <DefaultTableMessage
                        key={loaderKey}
                        tableLoading={tableLoading}
                        state={
                            groupings.length === 0 ? 
                            'noFilters' :
                            !isSearchResult ? 'emptySearchResult' :
                            'noData'
                        }
                    />
                    : null
                }
            </div>

            </ScrollContainer>



            <div className=" tablet-size:!p-0">
                {
                    isChartData && showTable && chartData && typeof chartData === 'object' && 
                    ((Array.isArray(chartData) && chartData.length > 0) || (!Array.isArray(data) && Object.keys(data).length > 0)) && (
                        <>                   
                            <div className={"ring-stone-200 dark:ring-[#353E50] ring-2   rounded-tremor-small h-fit m-0 mt-3 tablet-size:mt-6 p-4 tablet-size:p-6 w-full max-w-[800px] flex justify-start"}>
                                {
                                    isChartLoader ? 
                                    <TableLoading /> : (
                                        <DonutChartHero data={chartData} setChartFilter={setChartFilter} chartFilter={chartFilter} groupings={groupings} />
                                    )
                                }
                                
                            </div>
                        </>
                    )
                }
            </div>




            
        </div>
    );
}
export default ForecastedRebills;
