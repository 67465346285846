import { ErrorsShownCustomerReportData, SubCategory } from "./errors-shown-customer.types";
import { ErrorsShownCustomerReport } from "./errors-shown-customer.types";

function transformData(
  input: ErrorsShownCustomerReportData, 
  categoryKey: string
): ErrorsShownCustomerReport[] {
  // 1. Получаем объект по ключу categoryKey, например input["geo"].
  //    Он выглядит так: { US: {stats: [...]}, CA: {stats: [...]}, ... }
  const dataForCategory = input[categoryKey] as Record<string,{ stats: Array<{ error_msg: string; errors: number }> }>;

  if (!dataForCategory) {
    // Если вдруг по этому ключу ничего нет,
    // сразу возвращаем пустой массив
    return [];
  }

  // 2. Обходим все ключи вложенного объекта (US, CA и т.д.).
  const result = Object.keys(dataForCategory).map((key) => {
    // Получаем массив "stats" у конкретной страны/ключа
    const stats = dataForCategory[key].stats || [];

    // Готовим мапу, чтобы группировать ошибки по error_msg
    const subCategoriesMap: { [reason: string]: SubCategory } = {};

    let totalCount = 0;

    // 3. Группируем ошибки
    stats.forEach(({ error_msg, errors }: { error_msg: string; errors: number }) => {
      totalCount += errors;

      if (!subCategoriesMap[error_msg]) {
        subCategoriesMap[error_msg] = {
          reason: error_msg,
          count: errors
        };
      } else {
        subCategoriesMap[error_msg].count += errors;
      }
    });

    // Превращаем объект группировки в массив
    const subCategories = Object.values(subCategoriesMap) as SubCategory[];

    // 4. Формируем объект результата (DeclineResponseReport)
    //    Важно указать [categoryKey]: key, чтобы подставить название поля динамически.
    const reportItem: ErrorsShownCustomerReport = {
      [categoryKey]: key,
      count: totalCount,
      subCategories
    };

    return reportItem;
  });

  return result;
}

export default transformData;
