import { v4 as uuidv4 } from 'uuid';
import { StatsInterface, TransformedTransactionPerformanceReportData } from '../types/transaction-report.types';
import { transformNames } from './config';

export const prepareDataForTransform = (data: any) => {
  if (Object.keys(data).length === 0) {
    return {};
  }
  
  const transformObject = (obj: any, objName: any) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== "stats") {
        if (typeof value === "object" && !Array.isArray(value)) {
          transformed[key] = Object.entries(value)
            .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
            .map(
              ([nestedKey, nestedValue]) => {
                if (nestedKey === "") {
                  return transformObject(nestedValue, "NaN");
                }
                return transformObject(nestedValue, nestedKey);
              }
            );
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: any } = {};
  Object.entries(data).forEach(
    ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
      result[mainKey] = Object.entries(mainValue)
        .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
        .map(
          ([nestedKey, nestedValue]) => {
            if (nestedKey === "") {
              return transformObject(nestedValue, "NaN");
            }
            return transformObject(nestedValue, nestedKey);
          }
        );
    }
  );
  return result;
};

export function transformData(data: any): TransformedTransactionPerformanceReportData[] | [] {
  const preparedData = prepareDataForTransform(data);
  function recursiveTransform(node: any): TransformedTransactionPerformanceReportData[] {
      if (Array.isArray(node)) {
        // Process each item in the array and collect the results
        const transformedArray = node.flatMap(item => recursiveTransform(item));
        // Sort the array here based on your sorting criteria
        // For example, sort by stats.clicks in descending order
        transformedArray.sort((a, b) => (b.stats.initial_sales || 0) - (a.stats.initial_sales || 0));
        return transformedArray;
      } else if (typeof node === 'object' && node !== null) {
      const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

      if (nameKey) {
          const name = node.name ?? '---';

          const stats: StatsInterface = {
            name,
            initial_sales: node.initial_sales || null,
            initial_declines: node.initial_declines || null,
            initial_approval_rate: node.initial_approval_rate || null,
            reproc_sales: node.reproc_sales || null,
            reproc_declines: node.reproc_declines || null,
            reproc_approval_rate: node.reproc_approval_rate || null,
            rebill_sales: node.rebill_sales || null,
            rebill_declines: node.rebill_declines || null,
            rebill_approval_rate: node.rebill_approval_rate || null,
            rebill_gross: node.rebill_gross || null,
            charge_back: node.charge_back || null,
            charge_back_percentage: node.charge_back_percentage || null,
            refunds: node.refunds || null,
            refund_amount: node.refund_amount || null,
            partial_refunds: node.partial_refunds || null,
            partial_refunds_amount: node.partial_refunds_amount || null,
            pending: node.pending || null,
            invalid: node.invalid || null,
            canceled_orders: node.canceled_orders || null,
            gross_revenue: node.gross_revenue || null,
            net_revenue: node.net_revenue || null,

            currency: node.currency || null,
          };

          const child_data: TransformedTransactionPerformanceReportData[] = [];

          for (const key in node) {
            if (transformNames.includes(key) && key !== nameKey) {
                const child = recursiveTransform(node[key]);
                child_data.push(...child);
            } else if (typeof node[key] === 'object') {
                const child = recursiveTransform(node[key]);
                child_data.push(...child);
            }
          }

          child_data.sort((a, b) => (b.stats.initial_sales || 0) - (a.stats.initial_sales || 0));

          return [{
            name,
            stats,
            child_data,
          }];
      } else {
          const child_data: TransformedTransactionPerformanceReportData[] = [];

          for (const key in node) {
          const child = recursiveTransform(node[key]);
          child_data.push(...child);
          }

          child_data.sort((a, b) => (b.stats.initial_sales || 0) - (a.stats.initial_sales || 0));

          return child_data;
      }
      }
      return [];
  }

  return recursiveTransform(preparedData);
}

// export const transformData = (data: TransactionReportData) => {
//     if (Object.keys(data).length === 0) {
//       return {};
//     }
    
//     const transformObject = (obj: any, objName: any) => {
//       const transformed = { ...obj.stats, name: objName, id: uuidv4() };
//       Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
//         if (key !== "stats") {
//           if (typeof value === "object" && !Array.isArray(value)) {
//             transformed[key] = Object.entries(value)
//               .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
//               .map(
//                 ([nestedKey, nestedValue]) => {
//                   if (nestedKey === "") {
//                     return transformObject(nestedValue, "NaN");
//                   }
//                   return transformObject(nestedValue, nestedKey);
//                 }
//               );
//           } else {
//             transformed[key] = value;
//           }
//         }
//       });
//       return transformed;
//     };
  
//     const result: { [key: string]: any } = {};
//     Object.entries(data).forEach(
//       ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
//         result[mainKey] = Object.entries(mainValue)
//           .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
//           .map(
//             ([nestedKey, nestedValue]) => {
//               if (nestedKey === "") {
//                 return transformObject(nestedValue, "NaN");
//               }
//               return transformObject(nestedValue, nestedKey);
//             }
//           );
//       }
//     );
    
//     return result;
//   };
