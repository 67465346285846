import getSymbolFromCurrency from 'currency-symbol-map';
import { SalesPaceReportRowInterface, TransactionReportData } from '../types/transaction-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(data: TransactionReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
    const result: { [key: string]: string }[] = [];
    const groupingLevels = new Set<number>();
    const filtersList = ['Currency', ...filters!] 

    function processItem(item: SalesPaceReportRowInterface, hierarchy: string[] = [], level: number = 0) {
      const baseItem: { [key: string]: string } = {
        initial_sales: Number(item?.initial_sales)?.toLocaleString() || '',
        initial_declines: Number(item?.initial_declines)?.toLocaleString() || '',
        initial_approval_rate: item?.initial_approval_rate ? parseFloat(item?.initial_approval_rate)?.toFixed(2) + '%' : '0.00%',
        reproc_sales: Number(item?.reproc_sales)?.toLocaleString() || '',
        reproc_declines: Number(item?.reproc_declines)?.toLocaleString() || '',
        reproc_approval_rate: item?.reproc_approval_rate ? parseFloat(item?.reproc_approval_rate)?.toFixed(2) + '%' : '0.00%',
        rebill_sales: Number(item?.rebill_sales)?.toLocaleString() || '',
        rebill_declines: Number(item?.rebill_declines)?.toLocaleString() || '',
        rebill_approval_rate: item?.rebill_approval_rate ? parseFloat(item?.rebill_approval_rate)?.toFixed(2) + '%' : '0.00%',
        rebill_gross: (item?.currency ? getSymbolFromCurrency(item?.currency) : '') + Number(item?.rebill_gross)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
        charge_back: Number(item?.charge_back)?.toLocaleString() || '',
        charge_back_percentage: item?.charge_back_percentage ? parseFloat(item?.charge_back_percentage)?.toFixed(2) + '%' : '0.00%',
        refunds: Number(item?.refunds)?.toLocaleString() || '',
        refund_amount: (item?.currency ? getSymbolFromCurrency(item?.currency) : '') + Number(item?.refund_amount)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
        partial_refunds: Number(item?.partial_refunds)?.toLocaleString() || '',
        partial_refunds_amount: (item?.currency ? getSymbolFromCurrency(item?.currency) : '') + Number(item?.partial_refunds_amount)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
        pending: Number(item?.pending)?.toLocaleString() || '',
        invalid: Number(item?.invalid)?.toLocaleString() || '',
        canceled_orders: Number(item?.canceled_orders)?.toLocaleString() || '',
        gross_revenue: (item?.currency ? getSymbolFromCurrency(item?.currency) : '') + Number(item?.gross_revenue)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
        net_revenue: (item?.currency ? getSymbolFromCurrency(item?.currency) : '') + Number(item?.net_revenue)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
      };

      if (item.name || hierarchy.length > 0) {
        groupingLevels.add(level);
      }

      const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

      baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

      result.push(baseItem);

      Object.keys(namesEnum).forEach((key) => {
        const nestedKey = namesEnum[key];
        if (Array.isArray(item[nestedKey])) {
          item[nestedKey].forEach((nestedItem: SalesPaceReportRowInterface) =>
            processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
          );
        }
      });
    }

    if (data.currency && Array.isArray(data.currency)) {
      // @ts-ignore
      data.currency.forEach((currency) => processItem(currency));
    }

    const groupingFields = Array.from(groupingLevels).map((level) => {
      const foundKey = getKeyByValue(namesEnum, filtersList[level]);
      return foundKey ?? filtersList[level];
    });

    return result.map((item) => {
      const transformedItem: { [key: string]: string } = {};
      groupingFields.forEach((field) => {
        if (field === 'Currency' && item[field]) {
          transformedItem[field] = `${item[field]?.toUpperCase()} - ${getSymbolFromCurrency(item[field])}`;
        } else {
          transformedItem[field] = item[field] || '';
        }
      });
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[fieldsForCSV[key]];
      });
      return transformedItem;
    });
  }

  export default transformDataToCSVFormat;