import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { namesEnumStatus } from "./config";
import { FulfillmentSummaryReportDataStatus, FulfillmentSummaryReportDataTransformed } from "./types/fulfillment-summary-report.types";

export const transformData = (data: FulfillmentSummaryReportDataStatus) => {
    const result: FulfillmentSummaryReportDataTransformed[] = [];

    Object.keys(data).forEach((key) => {
        result.push({
            name: capitalizeSpecificWords(namesEnumStatus[key]),
            value: data[key as keyof FulfillmentSummaryReportDataStatus],
        });
    });

    return result;
}