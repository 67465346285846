import React from 'react';
import CopyIcon from "../../../assets/icons/Copy";
import FreezeIcon from "../../../assets/icons/Freeze";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import Utils from "../../../odinForgeService/Utils";
import {useLoadingContext} from "../loading/LoadingContext";
import RemovePopup from "../removePopup/RemovePopup";
import DefaultButton from '../buttons/DefaultButton';
import CSVExportButton from '../CSVExportButton/CSVExportButton';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DeclineResponseReport, DeclineResponseReportClonedData } from '../../../types/decline-response-report.types';
import { ConversationReportClonedData } from '../../pages/reports/conversationReport/utils/types/conversation-report.types';
import { Tooltip } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CustomActionButtonsProps<T1,T2,T3, T4> {
    copyButton: boolean;
    freezeButton: boolean;
    showDelete: boolean;
    clonedReportId: string | number | null | undefined;
    passId: (id: string | number | null | undefined) => void;
    setDeletePopup: (value: boolean) => void;
    deletePopup: boolean;
    clonedData: T1 | DeclineResponseReportClonedData | ConversationReportClonedData;
    setFreezeButton: (value: boolean) => void;
    setCopyButton: (value: boolean) => void;
    configRequestBody: object;
    generateClonedData: (id: number) => void;
    data?: T2 | DeclineResponseReport[];
    fieldsForCSV?: { [key: string]: string };
    disabledCSV?: boolean;
    namesEnum?: { [key: string]: string };
    reportName?: string;
    startDate?: string | dayjs.Dayjs;
    endDate?: string | dayjs.Dayjs;
    isCSV?: boolean;
    transformDataToCSVFormat?: (data: T3, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
        [key: string]: string | number | T4;
      }[];
    groupings?: string[];
    setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
    refreshKey?: number
    selectedType?: string
    isCustomFilters?: boolean
}

const CustomActionButtons = <T1,T2,T3,T4,> ({
                                                               copyButton,
                                                               freezeButton,
                                                               showDelete,
                                                               clonedReportId,
                                                               passId,
                                                               setDeletePopup,
                                                               deletePopup,
                                                               clonedData,
                                                               setFreezeButton,
                                                               setCopyButton,
                                                               configRequestBody,
                                                               generateClonedData,
                                                                data,
                                                                fieldsForCSV,
                                                                reportName,
                                                                startDate,
                                                                endDate,
                                                                namesEnum,
                                                                disabledCSV, 
                                                                isCSV = false,
                                                                transformDataToCSVFormat,
                                                                groupings,
                                                                setRefreshKey,
                                                                selectedType,
                                                                isCustomFilters
                                                           }: CustomActionButtonsProps<T1,T2,T3,T4>) => {
    const loadingContext = useLoadingContext();

    // @ts-ignore
    function hasGroupSequence(data) {
        return !!data.id;
    }

    const submitReportConfig = async () => {
        try {
            loadingContext.setLoading(true);
            let update = false
            if (hasGroupSequence(clonedData)) {
                update = true;
            }
            await OdinForgeService.instance().postReportConfig(configRequestBody, update, clonedReportId);
            setFreezeButton(false);
            setCopyButton(false)
            setRefreshKey && setRefreshKey(prevKey => prevKey + 1);
            Utils.instance().onSuccess('Changes are saved')
        } catch (error) {
            Utils.instance().onFailure('An error occurred')
        } finally {
            setFreezeButton(true);
            loadingContext.setLoading(false);
        }
    };

    const deleteCopyReport = async () => {
        try {
            await OdinForgeService.instance().deleteReportConfig(clonedReportId);
            loadingContext.setLoading(true);
            passId(clonedReportId)
            Utils.instance().onSuccess('Removed Report');
            setDeletePopup(false);
            loadingContext.setLoading(false);
        } catch (er){
            Utils.instance().onFailure('An error occurred')
        }
    }

    const cloneReport = async () => {
        try {
            loadingContext.setLoading(true);
            const response = await OdinForgeService.instance().postReportConfig(configRequestBody,false, clonedReportId);
            generateClonedData(response.data.data.id)
            Utils.instance().onSuccess('Report Copied')
        }catch (error) {
            Utils.instance().onFailure('An error occurred')
        }finally {
            loadingContext.setLoading(false);
        }
    }

    function getDeleteButton() {
        return (
            <Tooltip
                title={"Delete board"}
                placement={"bottom"}
                classes={{
                    tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                    arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                }}
                arrow
                enterTouchDelay={10}
                leaveTouchDelay={1000}
            >
                <span>
                    <DefaultButton
                        onClick={()=>setDeletePopup(true)}
                        type="button" variant="outlined"
                        color='error'
                        className={`flex border-2 w-16 h-10 mr-3 rounded-3xl justify-center delete items-center`}
                        label={
                            <DeleteIcon fill={copyButton ? '#677C9F' : '#0079FF'} />
                        }
                    />
                </span>
            </Tooltip>
        )
    }

    return (
        <div className={'flex items-end flex-row  justify-between tablet-size:w-auto tablet-size:justify-normal gap-4 tablet-size:gap-7 flex-wrap'}>
            {deletePopup && <RemovePopup onClose={() => setDeletePopup(false)} onNoClick={() => setDeletePopup(false)}
                                         removeText="Confirm you want to remove this report?"
                                         onYesClick={deleteCopyReport}/>}

            <div className='flex flex-wrap gap-3'>
                {
                    isCSV && fieldsForCSV && namesEnum && transformDataToCSVFormat && (
                        <CSVExportButton
                            data={data as T3}
                            fieldsForCSV={fieldsForCSV}
                            reportName={reportName}
                            startDate={startDate}
                            endDate={endDate}
                            namesEnum={namesEnum}
                            disabledCSV={disabledCSV}
                            transformDataToCSVFormat={transformDataToCSVFormat}
                            filters={groupings}
                            selectedType={selectedType}
                            isCustomFilters={isCustomFilters}
                        />
                    )
                }
                <Tooltip
                    title={disabledCSV ? "Unable to duplicate board. Please select at least one filter" : "Duplicate board"}
                    placement={"bottom"}
                    classes={{
                        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                    }}
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={1000}
                >
                    <span>
                        <DefaultButton disabled={copyButton || disabledCSV} onClick={cloneReport} type="button" variant="outlined" className="h-10" label={
                            <CopyIcon fill={copyButton || disabledCSV ? '#677C9F' : '#0079FF'} />
                        } />
                    </span>
                </Tooltip>

                <Tooltip
                    title={disabledCSV ? "Unable to save settings. Please select at least one filter" : !freezeButton ? "Save filter settings" : "Your filters are already saved"}
                    placement={"bottom"}
                    classes={{
                        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                    }}
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={1000}
                >
                    <span>
                        <DefaultButton disabled={freezeButton || disabledCSV} onClick={submitReportConfig} type="button" variant="outlined" className="h-10" label={
                            <FreezeIcon fill={`${freezeButton || disabledCSV ? '#677C9F' : '#0079FF' }`}/>
                        } />
                    </span>
                </Tooltip>
                
                
                {showDelete ? getDeleteButton() : null}
            </div>
        </div>
    );
};

export default CustomActionButtons;
