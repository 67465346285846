import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Offer',
    'Subid',
    'Pubid',
];

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Offer': 'offer',
    'Subid': 'subid',
    'Pubid': 'pub_id',
};

export const transformNames = [
    'geo',
    'affiliate_name',
    'offer',
    'subid',
    'pub_id',
];

export const firstRowData = [
    { id: 1, name: 'Canceled Subscriptions', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 2, name: 'Cancellation %', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 3, name: 'Customer Called To Cancel', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 4, name: 'Blacklisted', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 5, name: 'Fake Customer', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 6, name: 'Ethoca Alert', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 7, name: 'Order Cancelled', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 8, name: 'Unknown Cancellation Reason', fontSize: "12px", width: "220px", padding: "16px 8px 16px 8px",   hasSortField: true }
];

export const fields = {
    "Canceled Subscriptions": "cancelled_subscriptions",
    "Cancellation %": "cancellation_percentage",
    "Customer Called To Cancel": "customer_called_to_cancel",
    "Blacklisted": "blacklisted",
    "Fake Customer": "fake_customer",
    "Ethoca Alert": "ethoca_alert",
    "Order Cancelled": "order_cancelled",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
} as const;

export const fieldsForCSV = {
    "Canceled Subscriptions": "cancelled_subscriptions",
    "Cancellation %": "cancelation_percentage",
    "Customer Called To Cancel": "customer_called_to_cancel",
    "Blacklisted": "blacklisted",
    "Fake Customer": "fake_customer",
    "Ethoca Alert": "ethoca_alert",
    "Order Cancelled": "order_cancelled",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
};

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(350 + ((Number(groupingsCount)) * 8)) - ((row.depth + 1) * 32)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.cancelled_subscriptions,
            id: 'Canceled Subscriptions',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Canceled Subscriptions',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.cancelation_percentage,
            id: 'Cancellation %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Cancellation %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.customer_called_to_cancel,
            id: 'Customer Called To Cancel',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Customer Called To Cancel',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.blacklisted,
            id: 'Blacklisted',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Blacklisted',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.fake_customer,
            id: 'Fake Customer',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Fake Customer',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.ethoca_alert,
            id: 'Ethoca Alert',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Ethoca Alert',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.order_cancelled,
            id: 'Order Cancelled',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Order Cancelled',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.unknown_cancellation_reason,
            id: 'Unknown Cancellation Reason',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Unknown Cancellation Reason',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
        ]
}
