import { v4 as uuidv4 } from 'uuid';
import { transformNames } from './config';
import { CustomerPurchaseAttemptsReportData, StatsInterface, TransformedCustomerPurchaseAttemptsReportData } from '../types/customer-purchase-attempts.types';

export const prepareDataForTransform = (data: CustomerPurchaseAttemptsReportData) => {
  if (Object.keys(data).length === 0) {
    return {};
  }
  
  const transformObject = (obj: any, objName: string) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== "stats") {
        if (typeof value === "object" && !Array.isArray(value)) {
          transformed[key] = Object.entries(value)
            .sort((a: any, b: any) =>(b[1].stats?.number_of_all_attempts || 0) - (a[1].stats?.number_of_all_attempts || 0))
            .map(
              ([nestedKey, nestedValue]) => {
                if (nestedKey === "") {
                  return transformObject(nestedValue, "NaN");
                }
                return transformObject(nestedValue, nestedKey);
              }
            );
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: string | number | null | CustomerPurchaseAttemptsReportData | CustomerPurchaseAttemptsReportData[] } = {};
  Object.entries(data).forEach(
    ([mainKey, mainValue]: [mainKey: string, mainValue: any]) => {
      result[mainKey] = Object.entries(mainValue)
        .sort((a: any, b: any) =>(b[1].stats?.number_of_all_attempts || 0) - (a[1].stats?.number_of_all_attempts || 0))
        .map(
          ([nestedKey, nestedValue]) => {
            if (nestedKey === "") {
              return transformObject(nestedValue, "NaN");
            }
            return transformObject(nestedValue, nestedKey);
          }
        );
    }
  );
  return result;
};

export function transformData(data: CustomerPurchaseAttemptsReportData): TransformedCustomerPurchaseAttemptsReportData[] | [] {
  const preparedData = prepareDataForTransform(data);
  function recursiveTransform(node: any): any[] {
      if (Array.isArray(node)) {
        // Process each item in the array and collect the results
        const transformedArray = node.flatMap(item => recursiveTransform(item));
        // Sort the array here based on your sorting criteria
        // For example, sort by stats.clicks in descending order
        transformedArray.sort((a, b) => (b.stats.number_of_all_attempts || 0) - (a.stats.number_of_all_attempts || 0));
        return transformedArray;
      } else if (typeof node === 'object' && node !== null) {
      const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

      if (nameKey) {
          const name = node.name ?? '---';

          const stats: StatsInterface = {
            name,
            number_of_all_attempts: node.number_of_all_attempts || null,
            percentage_successful_attempts_all: node.percentage_successful_attempts_all || null,
            // first//
            number_of_first_attempts: node.number_of_first_attempts || null,
            percentage_of_successful_first_attempts: node.percentage_of_successful_first_attempts || null,
            difference_all_and_first_attempt_successes: node.difference_all_and_first_attempt_successes || null,
            // second//
            number_of_second_attempts: node.number_of_second_attempts || null,
            percentage_of_successful_second_attempts: node.percentage_of_successful_second_attempts || null,
            difference_first_and_second_attempt_successes: node.difference_first_and_second_attempt_successes || null,
            // third//
            number_of_third_attempts: node.number_of_third_attempts || null,
            percentage_of_successful_third_attempts: node.percentage_of_successful_third_attempts || null,
            difference_second_and_third_attempt_successes: node.difference_second_and_third_attempt_successes || null,
            // fourth//
            number_of_fourth_attempts: node.number_of_fourth_attempts || null,
            percentage_of_successful_fourth_attempts: node.percentage_of_successful_fourth_attempts || null,
            difference_third_and_fourth_attempt_successes: node.difference_third_and_fourth_attempt_successes || null,
            // customer 
            average_attempts_per_customer: node.average_attempts_per_customer || null,
            percentage_customers_with_declines: node.percentage_customers_with_declines || null,
            average_time_between_attempt_1_and_2: node.average_time_between_attempt_1_and_2 || null,
            average_time_between_attempt_2_and_3: node.average_time_between_attempt_2_and_3 || null,
            average_time_between_attempt_3_and_4: node.average_time_between_attempt_3_and_4 || null,
            //
          };

          const child_data: any[] = [];

          for (const key in node) {
            if (transformNames.includes(key) && key !== nameKey) {
                const child = recursiveTransform(node[key]);
                child_data.push(...child);
            } else if (typeof node[key] === 'object') {
                const child = recursiveTransform(node[key]);
                child_data.push(...child);
            }
          }

          child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

          return [{
            name,
            stats,
            child_data,
          }];
      } else {
          const child_data: any[] = [];

          for (const key in node) {
          const child = recursiveTransform(node[key]);
          child_data.push(...child);
          }

          child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

          return child_data;
      }
      }
      return [];
  }

  return recursiveTransform(preparedData);
}