import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BannedByBackend from '../../components/organism/BannedByBackend/BannedByBackend';
import { setShowModalGlobal } from '../../globals';
import { UserContext } from '../UserContext/UserContext';
import useTenantRedirect from '../../hooks/useTenantRedirect';

interface ModalContextProps {
    showModal: (message: string) => void;
    hideModal: () => void;
    modalMessage: string | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modalMessage, setModalMessage] = useState<string | null>(null);
    const userContext = useContext(UserContext);
    const { isSuperAdmin } = userContext!;

    const { redirectToAppropriateUrl } = useTenantRedirect({ isSuperAdmin });

    const showModal = (message: string) => {
        setModalMessage(message);
    };

    const hideModal = () => {        
        const tooManyRequestsValue = localStorage.getItem('tooManyRequests');

        localStorage.clear();

        if (tooManyRequestsValue !== null) {
            localStorage.setItem('tooManyRequests', tooManyRequestsValue);
        }

        setModalMessage(null);

        redirectToAppropriateUrl();
    };

    useEffect(() => {
        setShowModalGlobal(showModal);

        return () => {
            setShowModalGlobal(() => {});
        };
    }, []);

    return (
        <ModalContext.Provider value={{ showModal, hideModal, modalMessage }}>
            {children}
            {modalMessage && (
                <BannedByBackend onClose={hideModal} message={modalMessage} />
            )}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
