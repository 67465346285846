
import { createColumnHelper } from "@tanstack/react-table";
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { HourMetrics, PartnerMetrics, VarianceReporHourData, VarianceReporPartnerData } from "./types/variance-report.types";
import highlightMatch from "../../../../../helpers/highlightMatch";

dayjs.extend(utc);
dayjs.extend(timezone);


export const toggleOptions = [
    { value: 'affiliates', label: 'Affiliate' },
    { value: 'hours', label: 'Hour'},
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } } = {
  1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('day') },
  2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
  3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D')},
  4: { startDate: dayjs.tz().endOf('D').subtract(30, "d"), endDate: dayjs.tz().endOf('D')},
  5: { startDate: dayjs.tz().startOf('week'), endDate: dayjs.tz().endOf('week') },
  6: { startDate: dayjs.tz().startOf('month'), endDate: dayjs.tz().endOf('month') },
  7: { startDate: dayjs.tz().startOf('week').subtract(1, "week"), endDate: dayjs.tz().endOf('week').subtract(1, "week") },
  8: { startDate: dayjs.tz().startOf('month').subtract(1, "month"), endDate: dayjs.tz().endOf('month').subtract(1, "month") },
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
      1: { startDate: dayjs.tz().startOf('day').tz(timezone), endDate: dayjs.tz().endOf('day').tz(timezone) },
      2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
      3: { startDate: dayjs.tz().endOf('day').subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('day').tz(timezone) },
      4: { startDate: dayjs.tz().endOf('day').subtract(30, "d").tz(timezone), endDate: dayjs.tz().endOf('day').tz(timezone) },
      5: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().endOf('week').tz(timezone) },
      6: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().endOf('month').tz(timezone) },
      7: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().endOf('week').subtract(1, "week").tz(timezone) },
      8: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().endOf('month').subtract(1, "month").tz(timezone) },
    };
};

export const dateRangeList = [
  "Custom Range",
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
  "This Week",
  "This Month",
  "Last Week",
  "Last Month",
];

const columnHelper = createColumnHelper<VarianceReporHourData | VarianceReporPartnerData>();

export const tableColumns = [
    columnHelper.group({
      header: ' ',
      columns: [
        columnHelper.accessor('hour', {
          header: 'Hour',
          cell: info =>  info.getValue() ? info.getValue() + ':00' : '---',
          meta: { isFirstColumn: true },
        }),
      ],
    }),
    columnHelper.group({
      header: 'Clicks',
      meta: { 
        isBorderLeft: true,
        isBorderRight: true,
      },
      columns: [
        columnHelper.accessor('summary.clicks.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total clicks for the current hour across all days in the prior period.'
          },
        }),
        columnHelper.accessor('summary.clicks.current', {
          header: 'Current Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total clicks for the current hour across all days in the current period.'
          },
        }),
        columnHelper.accessor('summary.clicks.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows clicks growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelper.group({
      header: 'Conversions',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelper.accessor('summary.conversions.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total conversions for the current hour across all days in the prior period.'
          },
        }),
        columnHelper.accessor('summary.conversions.current', {
          header: 'Current Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total conversions for the current hour across all days in the current period.'
          },
        }),
        columnHelper.accessor('summary.conversions.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows conversions growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelper.group({
      header: 'Events',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelper.accessor('summary.events.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total events for the current hour across all days in the prior period.'
          },
        }),
        columnHelper.accessor('summary.events.current', {
          header: 'Current Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total events for the current hour across all days in the current period.'
          },
        }),
        columnHelper.accessor('summary.events.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows events growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelper.group({
      header: 'Revenue',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelper.accessor('summary.revenue.previous', {
          header: 'Prior Period',
          cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total revenue for the current hour across all days in the prior period.'
          },
        }),
        columnHelper.accessor('summary.revenue.current', {
          header: 'Current Period',
          cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total revenue for the current hour across all days in the current period.'
          },
        }),
        columnHelper.accessor('summary.revenue.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: false,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows revenue growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelper.group({
      header: 'EPC',
      meta: { 
        isBorderRight: false,
      },
      columns: [
        columnHelper.accessor('summary.epc.previous', {
          header: 'Prior Period',
          cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total EPC for the current hour across all days in the prior period.'
          },
        }),
        columnHelper.accessor('summary.epc.current', {
          header: 'Current Period',
          cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total EPC for the current hour across all days in the current period.'
          },
        }),
        columnHelper.accessor('summary.epc.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isLastInGroup: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows EPC growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
];
  
const subColumnHelper = createColumnHelper<PartnerMetrics>();
  
export const subColumns = (searchTerm: string, theme: 'light' | 'dark' = 'light') => [
    subColumnHelper.accessor('partner', {
      header: 'Partner',
      cell: info => highlightMatch(info.getValue(), searchTerm, theme),
    }),
    subColumnHelper.accessor('clicks.previous', {
      header: 'Previous Clicks',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelper.accessor('clicks.current', {
      header: 'Current Clicks',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelper.accessor('clicks.variance', {
      header: 'Clicks Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelper.accessor('conversions.previous', {
      header: 'Previous Conversions',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelper.accessor('conversions.current', {
      header: 'Current Conversions',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelper.accessor('conversions.variance', {
      header: 'Conversions Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelper.accessor('events.previous', {
      header: 'Previous Events',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelper.accessor('events.current', {
      header: 'Current Events',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelper.accessor('events.variance', {
      header: 'Events Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelper.accessor('revenue.previous', {
      header: 'Previous Revenue',
      cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelper.accessor('revenue.current', {
      header: 'Current Revenue',
      cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
    }),
    subColumnHelper.accessor('revenue.variance', {
      header: 'Revenue Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelper.accessor('epc.previous', {
      header: 'Previous EPC',
      cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelper.accessor('epc.current', {
      header: 'Current EPC',
      cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
    }),
    subColumnHelper.accessor('epc.variance', {
      header: 'EPC Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
];




const columnHelperPartner = createColumnHelper<VarianceReporHourData | VarianceReporPartnerData>();

export const tableColumnsPartner = (searchTerm: string, theme: 'light' | 'dark' = 'light') => [
    columnHelper.group({
      header: ' ',
      columns: [
        columnHelperPartner.accessor('partner', {
          header: 'Partner',
          cell: info => highlightMatch(info.getValue(), searchTerm, theme),
          meta: { isFirstColumn: true, isBorderRight: true },
        }),
      ],
    }),
    columnHelperPartner.group({
      header: 'Clicks',
      meta: { 
        isBorderLeft: true,
        isBorderRight: true,
      },
      columns: [
        columnHelperPartner.accessor('summary.clicks.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total clicks for the current hour across all days in the prior period.'
          },
        }),
        columnHelperPartner.accessor('summary.clicks.current', {
          header: 'Current',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total clicks for the current hour across all days in the current period.'
          },
        }),
        columnHelperPartner.accessor('summary.clicks.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows clicks growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelperPartner.group({
      header: 'Conversions',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelperPartner.accessor('summary.conversions.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total conversions for the current hour across all days in the prior period.'
          },
        }),
        columnHelperPartner.accessor('summary.conversions.current', {
          header: 'Current',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total conversions for the current hour across all days in the current period.'
          },
        }),
        columnHelperPartner.accessor('summary.conversions.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows conversions growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelperPartner.group({
      header: 'Events',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelperPartner.accessor('summary.events.previous', {
          header: 'Prior Period',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total events for the current hour across all days in the prior period.'
          },
        }),
        columnHelperPartner.accessor('summary.events.current', {
          header: 'Current',
          cell: info => info.getValue()?.toLocaleString(),
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total events for the current hour across all days in the current period.'
          },
        }),
        columnHelperPartner.accessor('summary.events.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows events growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelperPartner.group({
      header: 'Revenue',
      meta: { 
        isBorderRight: true,
      },
      columns: [
        columnHelperPartner.accessor('summary.revenue.previous', {
          header: 'Prior Period',
          cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total revenue for the current hour across all days in the prior period.'
          },
        }),
        columnHelperPartner.accessor('summary.revenue.current', {
          header: 'Current',
          cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total revenue for the current hour across all days in the current period.'
          },
        }),
        columnHelperPartner.accessor('summary.revenue.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isBorderRight: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows revenue growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
    columnHelperPartner.group({
      header: 'EPC',
      meta: { 
        isLastInGroup: true,
      },
      columns: [
        columnHelperPartner.accessor('summary.epc.previous', {
          header: 'Prior Period',
          cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isBorderLeft: true,
            isToolTip: true,
            tooltipMessage: 'Shows total EPC for the current hour across all days in the prior period.'
          },
        }),
        columnHelperPartner.accessor('summary.epc.current', {
          header: 'Current',
          cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
          meta: { 
            isToolTip: true,
            tooltipMessage: 'Shows total EPC for the current hour across all days in the current period.'
          },
        }),
        columnHelperPartner.accessor('summary.epc.variance', {
          header: 'Variance',
          cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
          meta: { 
            isLastInGroup: true,
            isColored: true,
            isToolTip: true,
            tooltipMessage: 'Shows EPC growth percentage, comparing the current hour in current date period to the same hour in the prior date range.'
          },
        }),
      ],
    }),
];
  
const subColumnHelperPartner = createColumnHelper<HourMetrics>();
  
export const subColumnsPartner = [
    subColumnHelperPartner.accessor('hour', {
      header: 'Hour',
      cell: info => info.getValue()  + ':00',
    }),
    subColumnHelperPartner.accessor('clicks.previous', {
      header: 'Previous Clicks',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelperPartner.accessor('clicks.current', {
      header: 'Current Clicks',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelperPartner.accessor('clicks.variance', {
      header: 'Clicks Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelperPartner.accessor('conversions.previous', {
      header: 'Previous Conversions',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelperPartner.accessor('conversions.current', {
      header: 'Current Conversions',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelperPartner.accessor('conversions.variance', {
      header: 'Conversions Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelperPartner.accessor('events.previous', {
      header: 'Previous Events',
      cell: info => info.getValue()?.toLocaleString(),
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelperPartner.accessor('events.current', {
      header: 'Current Events',
      cell: info => info.getValue()?.toLocaleString(),
    }),
    subColumnHelperPartner.accessor('events.variance', {
      header: 'Events Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelperPartner.accessor('revenue.previous', {
      header: 'Previous Revenue',
      cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelperPartner.accessor('revenue.current', {
      header: 'Current Revenue',
      cell: info => info.getValue() && info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
    }),
    subColumnHelperPartner.accessor('revenue.variance', {
      header: 'Revenue Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
    subColumnHelperPartner.accessor('epc.previous', {
      header: 'Previous EPC',
      cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
      meta: { 
        isBorderLeft: true,
      },
    }),
    subColumnHelperPartner.accessor('epc.current', {
      header: 'Current EPC',
      cell: info => info.getValue() && info.getValue() !== '---' ? `${getSymbolFromCurrency(info.row.original.currency)}${Number(info.getValue()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '---',
    }),
    subColumnHelperPartner.accessor('epc.variance', {
      header: 'EPC Variance',
      cell: info => info.getValue() && info.getValue() !== '---' ? Number(info.getValue()).toFixed(2) + '%' : '---',
      meta: { 
        isColored: true,
      },
    }),
];