import getSymbolFromCurrency from "currency-symbol-map";
import { FulfillmentSummaryReportData } from "./types/fulfillment-summary-report.types";

function transformDataToCSVFormat(data: FulfillmentSummaryReportData[], namesEnum: { [key: string]: string }): any {
    let isStatusTab = false;

    // @ts-ignore
    if (data[0]?.hasOwnProperty('value')) {
        isStatusTab = true;
    }
    
    const transformedData = data.map((item: FulfillmentSummaryReportData) => {
        if (isStatusTab) {
            const transformedItem : any = {
                // need to rename name to namesEnum
                [namesEnum.name]: item.name,
                // @ts-ignore
                'Count': item.value !== null && item.value !== undefined ? Number(item.value).toLocaleString() : '---',
            };
    
            return transformedItem;
        }
        const transformedItem : any = {
            // need to rename name to namesEnum
            [namesEnum.name]: item.name,
            'Hold': item.hold !== null && item.hold !== undefined ? Number(item.hold).toLocaleString() : '---',
            'Pending': item.pending !== null && item.pending !== undefined ? Number(item.pending).toLocaleString() : '---',
            'Shipped': item.shipped !== null && item.shipped !== undefined ? Number(item.shipped).toLocaleString() : '---',
            'Delivered': item.delivered !== null && item.delivered !== undefined ? Number(item.delivered).toLocaleString() : '---',
            'Canceled': item.cancelled !== null && item.cancelled !== undefined ? Number(item.cancelled).toLocaleString() : '---',
            'RMA Pending': item.rma_pending !== null && item.rma_pending !== undefined ? Number(item.rma_pending).toLocaleString() : '---',
            'Failed': item.failed !== null && item.failed !== undefined ? Number(item.failed).toLocaleString() : '---',
            'Sub Total': `${item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : ''}${item.sub_total !== null && item.sub_total !== undefined ? Number(item.sub_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`,
            'Shipping': item.shipping !== null && item.shipping !== undefined ? Number(item.shipping).toLocaleString() : '---',
            'Gross Revenue': `${item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : ''}${item.gross_revenue !== null && item.gross_revenue !== undefined ? Number(item.gross_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`,
            'Refunds': `${item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : ''}${item.refunds !== null && item.refunds !== undefined ? Number(item.refunds).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`,
            'Net Revenue': `${item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : ''}${item.net_revenue !== null && item.net_revenue !== undefined ? Number(item.net_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`,
        };

        return transformedItem;
    });

    return transformedData;
}

export default transformDataToCSVFormat;
