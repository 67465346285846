import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Offer',
    'Subid',
    'Pubid',
];

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Offer': 'offer',
    'Subid': 'subid',
    'Pubid': 'pub_id',
};

export const transformNames = [
    'geo',
    'affiliate_name',
    'offer',
    'subid',
    'pub_id',
];

export const firstRowData = [
    { id: 1, name: 'Canceled Orders', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 2, name: 'Cancellation %', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 3, name: 'Cancelled due to Refund', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 4, name: 'Blacklisted', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 5, name: 'Cannot Afford', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 6, name: 'Did not Order', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 7, name: 'Did not Receive', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 8, name: 'Doctor Advised', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 9, name: 'Does Not Like', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 10, name: 'Ethoca Alert', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 11, name: 'Possible Dispute', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 12, name: 'Product Not Working', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 13, name: 'Product Returned', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 14, name: 'Too Much Product', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 15, name: 'Was Not Aware of Terms', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 16, name: 'Customer Chargeback', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 17, name: 'Unknown Cancellation Reason', fontSize: "12px", width: "220px", padding: "16px 8px 16px 8px",   hasSortField: true }
];

export const fields = {
    "Canceled Orders": "canceled_orders",
    "Cancellation %": "cancelation_percentage",
    "Cancelled due to Refund": "cancelled_due_to_refund",
    "Blacklisted": "blacklist",
    "Cannot Afford": "cannot_afford",
    "Did not Order": "did_not_order",
    "Did not Receive": "did_not_receive",
    "Doctor Advised": "doctor_advised",
    "Does Not Like": "does_not_like",
    "Ethoca Alert": "ethoca_alerts",
    "Possible Dispute": "possible_dispute",
    "Product Not Working": "product_not_working",
    "Product Returned": "product_returned",
    "Too Much Product": "too_much_product",
    "Was Not Aware of Terms": "not_aware_of_trial_terms",
    "Customer Chargeback": "chargeback",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
} as const;

export const fieldsForCSV = {
    "Canceled Orders": "canceled_orders",
    "Cancellation %": "cancelation_percentage",
    "Cancelled due to Refund": "cancelled_due_to_refund",
    "Blacklisted": "blacklist",
    "Cannot Afford": "cannot_afford",
    "Did not Order": "did_not_order",
    "Did not Receive": "did_not_receive",
    "Doctor Advised": "doctor_advised",
    "Does Not Like": "does_not_like",
    "Ethoca Alert": "ethoca_alerts",
    "Possible Dispute": "possible_dispute",
    "Product Not Working": "product_not_working",
    "Product Returned": "product_returned",
    "Too Much Product": "too_much_product",
    "Was Not Aware of Terms": "not_aware_of_trial_terms",
    "Customer Chargeback": "chargeback",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
};

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(350 + ((Number(groupingsCount)) * 8)) - ((row.depth + 1) * 32)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.canceled_orders,
            id: 'Canceled Orders',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Canceled Orders',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.cancelation_percentage,
            id: 'Cancellation %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Cancellation %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.cancelled_due_to_refund,
            id: 'Cancelled due to Refund',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Cancelled due to Refund',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.blacklist,
            id: 'Blacklisted',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Blacklisted',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.cannot_afford,
            id: 'Cannot Afford',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Cannot Afford',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.did_not_order,
            id: 'Did not Order',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Did not Order',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.did_not_receive,
            id: 'Did not Receive',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Did not Receive',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.doctor_advised,
            id: 'Doctor Advised',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Doctor Advised',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.does_not_like,
            id: 'Does Not Like',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Does Not Like',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.ethoca_alerts,
            id: 'Ethoca Alert',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Ethoca Alert',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.possible_dispute,
            id: 'Possible Dispute',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Possible Dispute',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.product_not_working,
            id: 'Product Not Working',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Product Not Working',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.product_returned,
            id: 'Product Returned',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Product Returned',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.too_much_product,
            id: 'Too Much Product',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Too Much Product',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.not_aware_of_trial_terms,
            id: 'Was Not Aware of Terms',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Was Not Aware of Terms',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.chargeback,
            id: 'Customer Chargeback',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Customer Chargeback',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.unknown_cancellation_reason,
            id: 'Unknown Cancellation Reason',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Unknown Cancellation Reason',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
            },
            sortingFn: numericStringSortingFn,
          },


        ]
}
