enum TenantUrls {
    'crm2' = 'https://dashboard.swisscrm.com/',
    'tenant_1' = 'https://dashboard.swisscrm.com/',
    'dashboard-api' = 'https://dashboard.swisscrm.com/',
    'dashboard' = 'https://dashboard.swisscrm.com/',
    'pureadvertising-api' = 'https://pureadvertising.swisscrm.com/',
    'pureadvertising' = 'https://pureadvertising.swisscrm.com/',
    'xshield-api' = 'https://xshield.swisscrm.com/',
    'xshield' = 'https://xshield.swisscrm.com/',
    'goldenxr-api' = 'https://goldenxr.swisscrm.com/',
    'goldenxr' = 'https://goldenxr.swisscrm.com/',
    'demo' = 'https://demo.swisscrm.com/',
    'demo-api' = 'https://demo.swisscrm.com/',
}

enum TenantUrlsStaging {
    'crm2' = 'https://crm2.staging.swisspriv.com/',
    'tenant_1' = 'https://crm2.staging.swisspriv.com/',
    'dashboard-api' = 'https://crm2.staging.swisspriv.com/',
    'dashboard' = 'https://crm2.staging.swisspriv.com/',
    'pureadvertising-api' = 'https://crm2.staging.swisspriv.com/',
    'pureadvertising' = 'https://crm2.staging.swisspriv.com/',
    'xshield-api' = 'https://crm2.staging.swisspriv.com/',
    'xshield' = 'https://crm2.staging.swisspriv.com/',
    'goldenxr-api' = 'https://crm2.staging.swisspriv.com/',
    'goldenxr' = 'https://crm2.staging.swisspriv.com/',
    'demo' = 'https://crm2.staging.swisspriv.com/',
    'demo-api' = 'https://crm2.staging.swisspriv.com/',
}

export { TenantUrls, TenantUrlsStaging };
