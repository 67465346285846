import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D')},
    4: { startDate: dayjs.tz().endOf('D').subtract(30, "d"), endDate: dayjs.tz().endOf('D')},
    5: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('D') },
    6: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
    7: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('D') },
    8: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().startOf('w').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    10: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('D').subtract(1, "d").tz(timezone), endDate: dayjs.tz().endOf('D').subtract(1, "d").tz(timezone) },
        3: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(30, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, 
        5: { startDate: dayjs.tz().startOf('w').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('M').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        7: { startDate: dayjs.tz().startOf('y').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        8: { startDate: dayjs.tz().startOf('w').subtract(1, "w").tz(timezone), endDate: dayjs.tz().startOf('w').subtract(1, "s").tz(timezone) },
        9: { startDate: dayjs.tz().startOf('M').subtract(1, "M").tz(timezone), endDate: dayjs.tz().startOf('M').subtract(1, "s").tz(timezone) },
        10: { startDate: dayjs.tz().startOf('y').subtract(1, "y").tz(timezone), endDate: dayjs.tz().startOf('y').subtract(1, "s").tz(timezone) }
    };
  };