import { v4 as uuidv4 } from 'uuid';
import { StatsInterface, TransformedCancellationSubscriptionReportData } from '../types/cancellation-subscription-report.types';
import { transformNames } from './config';

export const prepareDataForTransform = (data: any) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value)
              .sort((a: any, b: any) =>(Number(b[1].stats?.cancelled_subscriptions) || 0) - (Number(a[1].stats?.cancelled_subscriptions) || 0))
              .map(
                ([nestedKey, nestedValue]) => {
                  if (nestedKey === "") {
                    return transformObject(nestedValue, "NaN");
                  }
                  return transformObject(nestedValue, nestedKey);
                }
              );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue)
          .sort((a: any, b: any) =>(Number(b[1].stats?.cancelled_subscriptions) || 0) - (Number(a[1].stats?.cancelled_subscriptions) || 0))
          .map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
      }
    );
    return result;
  };
  
  export function transformData(data: any): TransformedCancellationSubscriptionReportData[] | [] {
    const preparedData = prepareDataForTransform(data);
    function recursiveTransform(node: any): TransformedCancellationSubscriptionReportData[] {
        if (Array.isArray(node)) {
          // Process each item in the array and collect the results
          const transformedArray = node.flatMap(item => recursiveTransform(item));
          // Sort the array here based on your sorting criteria
          // For example, sort by stats.clicks in descending order
          transformedArray.sort((a, b) => (Number(b.stats.cancelled_subscriptions) || 0) - (Number(a.stats.cancelled_subscriptions) || 0));
          return transformedArray;
        } else if (typeof node === 'object' && node !== null) {
        const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');
  
        if (nameKey) {
            const name = node.name ?? '---';
  
            const stats: StatsInterface = {
              name,
              total_subscriptions: node.total_subscriptions || 0,
              cancelled_subscriptions: node.cancelled_subscriptions || 0,
              cancellation_percentage: node.cancellation_percentage || 0,
              customer_called_to_cancel: node.customer_called_to_cancel || 0,
              blacklisted: node.blacklisted || 0,
              fake_customer: node.fake_customer || 0,
              ethoca_alert: node.ethoca_alert || 0,
              order_cancelled: node.order_cancelled || 0,
              unknown_cancellation_reason: node.unknown_cancellation_reason || 0,
            };
  
            const child_data: TransformedCancellationSubscriptionReportData[] = [];
  
            for (const key in node) {
              if (transformNames.includes(key) && key !== nameKey) {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              } else if (typeof node[key] === 'object') {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              }
            }
  
            child_data.sort((a, b) => (Number(b.stats.cancelled_subscriptions) || 0) - (Number(a.stats.cancelled_subscriptions) || 0));
  
            return [{
              name,
              stats,
              child_data,
            }];
        } else {
            const child_data: TransformedCancellationSubscriptionReportData[] = [];
  
            for (const key in node) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
            }
  
            child_data.sort((a, b) => (Number(b.stats.cancelled_subscriptions) || 0) - (Number(a.stats.cancelled_subscriptions) || 0));
  
            return child_data;
        }
        }
        return [];
    }
  
    return recursiveTransform(preparedData);
}

// export const transformData = (data: CancellationSubscriptionReportData) => {
//     if (Object.keys(data).length === 0) {
//         return {};
//     }
//     const transformObject = (obj: any, objName: any) => {
//         const transformed = { ...obj.stats, name: objName, id: uuidv4() };
//         Object.entries(obj).forEach(([key, value]: [any, any]) => {
//             if (key !== "stats") {
//                 if (typeof value === "object" && !Array.isArray(value)) {
//                     transformed[key] = Object.entries(value)
//                         .sort((a: any, b: any) =>(b[1].stats?.cancelled_subscriptions || 0) - (a[1].stats?.cancelled_subscriptions || 0))
//                         .map(
//                             ([nestedKey, nestedValue]) => {
//                                 if (nestedKey === "") {
//                                     return transformObject(nestedValue, "NaN");
//                                 }
//                                 return transformObject(nestedValue, nestedKey);
//                             }
//                         );
//                 } else {
//                     transformed[key] = value;
//                 }
//             }
//         });
//         return transformed;
//     };

//     const result: { [key: string]: any } = {};
//     Object.entries(data).forEach(
//         ([mainKey, mainValue]: [any, any]) => {
//             result[mainKey] = Object.entries(mainValue)
//                 .sort((a: any, b: any) =>(b[1].stats?.cancelled_subscriptions || 0) - (a[1].stats?.cancelled_subscriptions || 0))
//                 .map(
//                     ([nestedKey, nestedValue]) => {
//                         if (nestedKey === "") {
//                             return transformObject(nestedValue, "NaN");
//                         }
//                         return transformObject(nestedValue, nestedKey);
//                     }
//                 );
//         }
//     );

//     return result;
// };
