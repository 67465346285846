import { createColumnHelper } from "@tanstack/react-table";
import { FulfillmentSummaryReportData, TabKey } from "./types/fulfillment-summary-report.types";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import getSymbolFromCurrency from 'currency-symbol-map';

dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
    'Campaign': 'campaign_name',
    'Status': 'status',
    'SKU': 'sku',
    'Product': 'product',
    'Fulfillment House': 'fulfillment_house',
    'Fulfillment Company': 'fulfillment_company',
};

export const names = [
    'Campaign',
    'Status',
    'SKU',
    'Product',
    'Fulfillment House',
    'Fulfillment Company',
];

export const namesMetricsTab: { [key: string]: string } = {
    'Campaign': 'campaign_name',
    'Status': 'status',
    'SKU': 'sku',
    'Product': 'product',
    'Fulfillment House': 'fulfillment_house',
    'Fulfillment Company': 'fulfillment_company',
}

export const namesEnumStatus: { [key: string]: string } = {
    'hold_total': 'Hold',
    'pending_shipment_total': 'Pending',
    'partial_shipped_total': 'Partial Shipped',
    'shipped_total': 'Shipped',
    'delivered_total': 'Delivered',
    'canceled_total': 'Cancelled',
    'rma_pending_total': 'RMA Pending',
    'failed_total': 'Failed',
};

const columnHelper = createColumnHelper<FulfillmentSummaryReportData | any>()

export const tableColumns = (activeTab: TabKey) => [
    columnHelper.accessor('name', {
        header: `${activeTab}`,
        cell: ({ getValue }) => capitalizeSpecificWords(getValue()),
        meta: {
            isSearch: true,
        }
    }),
    columnHelper.accessor('hold', {
        header: 'Hold',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('pending', {
        header: 'Pending',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('shipped', {
        header: 'Shipped',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.display({
        id: 'delivered',
        header: 'Delivered',
        cell: ({ row }) => {
          const { delivered } = row.original;
          return delivered !== null && delivered !== undefined
              ? Number(delivered).toLocaleString()
              : '---';
        },
        meta: {
          isAlignTextRight: true,
        },
    }),
    columnHelper.accessor('cancelled', {
        header: 'Cancelled',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('rma_pending', {
        header: 'RMA Pending',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('failed', {
        header: 'Failed',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('sub_total', {
        header: 'Sub Total',
        cell: ({ getValue, row }) => {
            const value = getValue();
            const currency = row.original.currency;
            return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('shipping', {
        header: 'Shipping',
        cell: ({ getValue, row }) => {
            const value = getValue();
            const currency = row.original.currency;
            return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('gross_revenue', {
        header: 'Gross Revenue',
        cell: ({ getValue, row }) => {
            const value = getValue();
            const currency = row.original.currency;
            return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('refunds', {
        header: 'Refunds',
        cell: ({ getValue, row }) => {
            const value = getValue();
            const currency = row.original.currency;
            return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;

        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('net_revenue', {
        header: 'Net Revenue',
        cell: ({ getValue, row }) => {
            const value = getValue();
            const currency = row.original.currency;
            return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
];

export const tableColumnsStatus = () => [
    columnHelper.accessor('name', {
        header: `Fulfillment State`,
        cell: ({ getValue }) => capitalizeSpecificWords(getValue()),
        meta: {
            isSearch: true,
        }
    }),
    columnHelper.accessor('value', {
        header: 'Count',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
                ? Number(value).toLocaleString()
                : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
];