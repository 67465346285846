import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { namesEnum, namesMetricsTab } from "./utils/config";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import PaginationState from "./utils/types/pagination.types";
import { UserContext } from "../../../../context/UserContext/UserContext";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { DataRangesParamsList, dateRangeList } from "../../../../types/enums/DataRangesParamsList";
import sanitizeInput from "../../../../helpers/sanitizeInput";
import { FulfillmentSummaryReportClonedData, FulfillmentSummaryReportData, FulfillmentSummaryReportDataResponse, TabKey } from './utils/types/fulfillment-summary-report.types';
import FilterBarFulfillmentSummaryReport from "../../../organism/filterbar/FilterBarFulfillmentSummaryReport";
import FulfillmentSummaryReportTable from "./components/FulfillmentSummaryReportTable";
import { transformData } from "./utils/transformData";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FulfillmentSummaryReportInterface {
    clonedData: FulfillmentSummaryReportClonedData
    passData: (data: FulfillmentSummaryReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const FulfillmentSummaryReport: React.FC<FulfillmentSummaryReportInterface> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
  const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
  const [groupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
  const [selectedGroupings] = React.useState(3)
  const [copyButton, setCopyButton] = React.useState(false);
  const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
  const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
  const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())

  const [data, setData] = React.useState<FulfillmentSummaryReportData[]>(clonedData['data']?clonedData['data']:[]);
  const reportName = ReportName.FulfillmentSummaryReport;
  const [clonedReportId] = React.useState(clonedData['id'])
  const [showDelete] = React.useState(clonedData['type'] === 'cloned')
  const [deletePopup, setDeletePopup] = React.useState(false)
  const [tableLoading, setTableLoading] = useState(true);
  const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);

  const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<FulfillmentSummaryReportData[]>([]);
  const [transformedNamesEnumCSV, setTransformedNamesEnumCSV] = useState<{ [key: string]: string }>({});

  const groupSequence = groupings;

  const reportConfigValues = useRef({ reportName, groupSequence });

  const tabs: TabKey[] = ['Campaign', 'Status', 'SKU', 'Product', 'Fulfillment House', 'Fulfillment Company'];
  const [activeTab, setActiveTab] = useState<TabKey>(clonedData['filterTab']?clonedData['filterTab']:'Campaign');

  const [loaderKey, setLoaderKey] = useState(0);

  const isUserInitiated = useRef(false);

  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const [pagination, setPagination] = React.useState<PaginationState>({
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalItems: 0,
      currentPage: 1,
  })

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const [csvFilters, setCsvFilters] = useState<string[]>([])

  useEffect(() => {
    if ((startDate as Dayjs).isAfter(endDate)) {
        setEndDate(startDate);
    }
  },[startDate,endDate])

  const previousValuesRef = useRef({
    groupings: JSON.stringify(groupings),
    dateRange: dateRange,
    startDate: startDate,
    endDate: endDate,
    activeTab: activeTab,
  });

  useEffect(() => {
    const isButtonActive = selectedGroupings > 0;

    const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
    const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
    const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
    const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

    const valuesChanged = (
        JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
        || dateRange !== clonedData['date_range']
        || startDateStr !== clonedStartDateStr
        || endDateStr !== clonedEndDateStr
        || activeTab !== clonedData['filterTab']
    );
    if (valuesChanged) {
        setFreezeButton(!isButtonActive);
    }
    if (!valuesChanged) {
        setFreezeButton(true)
    }
    reportConfigValues.current = { reportName, groupSequence };

    // Refetch if date range is not custom
    const previousValues = previousValuesRef.current;
    if (dateRange && (
        previousValues.groupings !== JSON.stringify(groupings) ||
        previousValues.dateRange !== dateRange ||
        previousValues.startDate !== startDate ||
        previousValues.endDate !== endDate ||
        previousValues.activeTab !== activeTab
    )) {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            dateRange: dateRange,
            startDate: startDate,
            endDate: endDate,
            activeTab: activeTab,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupings, reportName, dateRange, startDate, endDate, activeTab, ]);

  function generateParams() {
    const params = new URLSearchParams();

    params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
    params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
    let filters: string[] = []
    groupings.forEach((value) => {
        filters.push((namesEnum)[value])
    })

    params.append('filters[0]', namesMetricsTab[activeTab]);

    params.append('currentPage', pagination.pageIndex?.toString());
    params.append('pageSize', pagination.pageSize?.toString());

    params.append('timezone', timezone) 

    if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
    } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
    }

    params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

    params.append('search', sanitizeInput(localStorage.getItem('searchTerm')?.trim() ?? ''));

    return params;
  }

  function generateObjectToHash(){
    let filters: string[] = []
    groupings.forEach((value) => {
        filters.push((namesEnum)[value])
    })
    const dataToHash :object = {
        filters: filters,
        startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
        endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
        dateRange: dateRange,
        filterTab: activeTab,
    }

    return dataToHash;
  }

  async function handleBlur(isRefresh?: boolean) {
    setTableLoading(true);
    if (isRefresh) {} else {
        setSearchTerm('');
        localStorage.removeItem('searchTerm');
    }
    const params = generateParams()!
    setData([]);
    const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
    if (cachedData){
        setTableLoading(false);
        setData(cachedData)
    } else {
        OdinForgeService.instance().getFulfillmentSummaryReportData(params, clonedReportId)
            .then(async (result: {data: FulfillmentSummaryReportDataResponse}) => {
                setTableLoading(false);
                if (result && result?.data?.status !== 'error') {
                    setPagination((prev) => ({
                        ...prev,
                        pageIndex: activeTab === 'Status' ? 1 : result.data.current_page,
                        pageSize: activeTab === 'Status' ? 10 : result.data.per_page,
                        totalPages: result.data.last_page,
                        totalItems: result.data.total,
                    }));
                    // @ts-ignore
                    setData(activeTab === 'Status' ? transformData(result.data[0]) : result.data.data);

                    createHash(generateObjectToHash, result.data.data, reportName)
                } else {
                    setTableLoading(false);
                    setData([]);
                }
            })
            .catch((error) => {
                if (error.code === 'ERR_CANCELED') {
                    setLoaderKey(prevKey => prevKey + 1);
                    console.log('Request canceled');
                } else {
                    setTableLoading(false);
                    Utils.instance().onFailure('An error occurred while fetching the report');
                }
            });
        }
  } 

  useEffect(() => {
    if (isMobile) {
        if (isFirstRender) {
            localStorage.removeItem('searchTerm');
            setIsFirstRender(false);
            return;
          }
          handleBlur();
    }
   
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function generateConfigRequestBody() {
    return {
        filters: {
            group_sequence: groupings,
            date_range: dateRange,
            start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
            end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            filterTab: activeTab,
        },
        report_name: reportName
    }
  }

function generateClonedData(reportId: number)  {
    const dataToPass = {
        group_sequence: groupings,
        id: reportId,
        start_date: startDate,
        end_date: endDate,
        data: data,
        type: 'cloned',
        date_range: dateRange,
        filterTab: activeTab,
    }
    passData(dataToPass)
  }

  const handleTabClick = (tab: TabKey) => {
    setTableLoading(true);
    isUserInitiated.current = true;
    setActiveTab(tab);
    setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
      }));
  };

  const handlePageChange = (newPageIndex: number) => {
    isUserInitiated.current = true;
    setPagination((prev) => ({
      ...prev,
      pageIndex: newPageIndex + 1,
    }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
      isUserInitiated.current = true;
      setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
        pageSize: newPageSize,
      }));
  };

  useEffect(() => {
    handleBlur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dateRange, superTenant]);

  function updatePaginationOptions() {
    isUserInitiated.current = true;
    setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
        pageSize: 10,
    }));
  }

  const handleSearch = (isReset = false) => {
    localStorage.setItem('searchTerm', searchTerm);
    if (!isReset) {
        setSearchTerm('');
        localStorage.removeItem('searchTerm');
    }

    setIsSearchTriggered(true);

    setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
    }));
  };

  useEffect(() => {
    setCsvFilters(Array.from(new Set([
        `${namesEnum[activeTab]}`,
        ...(searchTerm ? [`search-${searchTerm}`] : []), 
    ])))
  }, [activeTab, searchTerm])

  useEffect(() => {
    if (isSearchTriggered) {
        handleBlur(true);
        setIsSearchTriggered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchTriggered]);

  useEffect(() => {
      localStorage.removeItem('searchTerm');
      setSearchTerm('');
      // update pagination
      setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
        pageSize: 10,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // Temporarly it should be last useEffect
  useEffect(() => {
    if (isFirstRender) {
        localStorage.removeItem('searchTerm');
        setIsFirstRender(false);
        return;
    }
  
    if (isUserInitiated.current) {
      isUserInitiated.current = false; // Reset the flag
      handleBlur(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize,  isUserInitiated.current, activeTab]);

  return (
    <div className={"pb-10"} >
        <div className="!p-6 tablet-size:!p-0">
        <FilterBarFulfillmentSummaryReport
            handleBlur={updatePaginationOptions}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedGroupings={selectedGroupings}
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            generateConfigRequestBody={generateConfigRequestBody}
            generateClonedData={generateClonedData}
            title={ReportName.FulfillmentSummaryReport}
            //////////////////////// for CSV btn
            data={transformedTableDataCSV}
            fieldsForCSV={transformedNamesEnumCSV}
            disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
            namesEnum={{
                name: activeTab
            }}
            isCSV={true}
            transformDataToCSVFormat={transformDataToCSVFormat}
            csvFilters={csvFilters}
            isCustomFilters={true}
            ////////////////////////
            //////////////////////// FilterTabs Props
            tabs={tabs}
            activeTab={activeTab} 
            handleTabClick={handleTabClick}
            //////////////////////// ChartSelectDropdown Props
            isDisableNotNeeded={true}
            isNotNeedFetchData={true}
            // Refresh cloneData
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            // SearchRowProps
            isSearch={true}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
        />
        </div>
        {
            data && data.length !== 0 && pagination && !tableLoading ? (
                <FulfillmentSummaryReportTable 
                    data={data} 
                    onPageChange={handlePageChange} 
                    paginationState={pagination} 
                    onPageSizeChange={handlePageSizeChange} 
                    activeTab={activeTab} 
                    setTransformedTableDataCSV={setTransformedTableDataCSV} 
                    setTransformedNamesEnumCSV={setTransformedNamesEnumCSV} 
                    searchTerm={searchTerm}
                />
            ) : (
                <div className="mt-6">
                    <DefaultTableMessage
                        key={loaderKey}
                        tableLoading={tableLoading}
                        state={
                            'noData'
                        }
                    />
                </div>
            )
        }
        
    </div>
);
}

export default FulfillmentSummaryReport