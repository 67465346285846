import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid (C1)': 'c1',
    'Campaign Category': 'campaign_category_name',
};
  
export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid (C1)',
    'Campaign Category',
];
  
export const tableHeadingNames = {
    'GEO': 'geo',
    'Affiliate': 'affiliateName',
    'Merchant Account': 'merchantName',
    'Offer': 'offerName',
    'Subid (C1)': 'subidC1',
    'Campaign Category': 'campaignCategory',
}