import React, { useCallback, useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/PaceReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import '../../../../css/TransactionReport.css'
import FilterBar from "../../../organism/filterbar/FilterBar";
import {useTheme} from "../../../../context/ThemeContext/ThemeContext";
import { reorderLevels, updateExpandedState, determineNestingLevels } from "../../../../helpers/collapsingFunctions";
import { prepareDataForTransform, transformData } from "./utils/transformData";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { NestedLevels, TransactionReportClonedData, TransactionReportData, TransformedTransactionPerformanceReportData } from "./types/transaction-report.types";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { DataRangesParamsList, dateRangeList } from "../../../../types/enums/DataRangesParamsList";
import { generateRangeConfigs } from "../../../../types/enums/RangeConfigs";
import ReportTable from "../../../organism/ReportTable/ReportTable";
import { fieldsForCSV, getColumns, namesEnum } from "./utils/config";

dayjs.extend(utc);
dayjs.extend(timezone);


interface props {
    names: string[]
    clonedData: TransactionReportClonedData
    passData: (data: TransactionReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}
const TransactionReport: React.FC<props> = ({
                                        names,
                                        clonedData,
                                        passData,
                                        passId,
                                        setRefreshKey,
                                        refreshKey,
                                     }: props) => {
    const {theme} = useTheme()
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings, setSelectedGroupings] = React.useState(3)
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'0');
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const params = new URLSearchParams();
    const [showTable, setShowTable] = React.useState(!!clonedData['data'])
    const [data, setData] = React.useState<TransactionReportData | []>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.TransactionPerformanceReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
    const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const [previousEndDate, setPreviousEndDate] = useState(endDate);
    const [openEditTableDialog, setOpenEditTableDialog] = useState(false);
    const [actualColumnList, setActualColumnList] = useState<number[]>(clonedData['column_list']?clonedData['column_list']:[]);
    const [columnList, setColumnList] = useState<number[]>(actualColumnList);
    const [dialogApplyButton, setDialogApplyButton] = useState(true);

    let transformedGroupings = ['Currency', ...groupings];

    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});
    const [sortFieldShow, setSortFieldShow] = useState<null | number>(null);

    const isInitialLoadCollapse = useRef(true);

    const isInitialLoad = useRef(true);

    const [loaderKey, setLoaderKey] = useState(0);

    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');

    const [globalFilter, setGlobalFilter] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<TransformedTransactionPerformanceReportData[]>([]);

    const [csvFilters, setCsvFilters] = useState<string[]>([])

    useEffect(() => {
        if (data && collapse !== undefined) {   
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    function setCachedData() {
        const cachedDataJson = sessionStorage.getItem('transactionReportData')
        const cachedData = cachedDataJson ? JSON.parse(cachedDataJson) : null
        if (clonedReportId === cachedData.reportId) {
            setGroupings(cachedData.groupings)
            setCollapse(cachedData.collapse)
            setData(cachedData.data)
            setDateRange(cachedData.dateRange)
            setActualColumnList(cachedData.columnList)
            setShowTable(true)
            setSelectedGroupings(groupings.length);
            sessionStorage.removeItem('transactionReportData')
        }else {
            handleBlur()
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('transactionReportData'))
            setCachedData()
        else
            if (dateRange !== 0) handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
        }    },[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');
        
        const valuesChanged = (
            collapse !== clonedData['expand_level']
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || actualColumnList !== clonedData['column_list']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.collapse !== collapse ||
            previousValues.dateRange !== dateRange
        )) {
            handleBlur();
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                collapse: collapse,
                dateRange: dateRange
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, actualColumnList, startDate, endDate]);

    useEffect(() => {
        if (dateRange === 0) {
            handleBlur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings]);

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            collapse: collapse,
            dateRange: dateRange
        };
    }, [groupings, collapse, reportName, dateRange, theme]); 

    function generateParams() {
        if (dateRange === 0) {
            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        } else {
            const { startDate, endDate } = (generateRangeConfigs(timezone))[dateRange];

            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        }

        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })
        params.append('timezone', timezone) 
        
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }
        
        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
        }

        return dataToHash;
    }

    const isDataSame = (groupings: string[], previousGroupings: string[], startDate: string | Dayjs, previousStartDate: string | Dayjs, superTenant: string | null = null, previousSuperTenant: string | null = null) => {
        if (!isSuperAdmin) {
            return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate);
        }
        return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && superTenant === previousSuperTenant);
    };

    useEffect(() => {
        if (!isInitialLoad.current) {
            handleBlur();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    function handleBlur(isRefresh?: boolean) {
        if (tenantId === null) return;
        setShowTable(false);
        setTableLoading(true);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        if (isRefresh) {} else {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
        generateParams()
        if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant) || isRefresh) {
            setData([]);
            OdinForgeService.instance().getTransactionData(params, clonedReportId)
                .then((result: {data: TransactionReportData, cacheTime: number}) => {
                    setTableLoading(false);
                    if (result.data[(namesEnum)[(transformedGroupings)[0]]] && (result.data[(namesEnum)[(transformedGroupings)[0]]]! as []).length !== 0){
                        const transformedData = transformData(result.data)
                        // @ts-ignore
                        setData(transformedData);
                        const csvData = prepareDataForTransform(result.data)
                        // @ts-ignore
                        setTransformedTableDataCSV(csvData)
                        setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))
                        setSelectedGroupings(transformedGroupings.length);

                        createHash(generateObjectToHash, result.data, reportName, result.cacheTime)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName, result.cacheTime)
                        setShowTable(true);

                        // It is for expanding the table to the last level
                        if (!isInitialLoadCollapse.current) {
                            setCollapse(transformedGroupings.length.toString());
                        } else {
                            isInitialLoadCollapse.current = false;
                        }
                    } else {
                        setTableLoading(false);
                        setData([]);
                        // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
        } else {
            if (data.length!==0)
                setShowTable(true)
            setTableLoading(false);
        }
        setPreviousGroupings(groupings);
        setPreviousStartDate(startDate)
        setPreviousEndDate(endDate)
        setPreviousSuperTenant(superTenant);
    }

    useEffect(() => {
        if (columnList.sort().toString() === actualColumnList.sort().toString() || columnList.length>15){
            setDialogApplyButton(true)
        }
        else {
            setDialogApplyButton(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnList]);

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                column_list: columnList,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            expand_level: collapse,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            column_list: actualColumnList
        }
        passData(dataToPass)
    }

    useEffect(() => {
        if (sortFieldShow && actualColumnList.includes(sortFieldShow)) {
            setSortFieldShow(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualColumnList]);

    useEffect(() => {
        setCsvFilters(Array.from(new Set([
            ...groupings.map((grouping) => `${namesEnum[grouping]}`),
            ...(searchTerm ? [`search-${searchTerm}`] : []), 
        ])))
    }, [groupings, searchTerm])

    const handleSearch = (isReset = false) => {
        if (searchTerm !== '') localStorage.setItem('searchTerm', searchTerm);

        if (!isReset) {
            setSearchTerm(''); 
            localStorage.removeItem('searchTerm');
            setGlobalFilter('')
            setIsNoData(false)
        } else {
            setGlobalFilter(searchTerm)
        }

        handleBlur(true)
    };

    useEffect(() => {
        setCsvFilters(Array.from(new Set([
            ...groupings.map((grouping) => `${namesEnum[grouping]}`),
            ...(searchTerm ? [`search-${searchTerm}`] : []), 
        ])))
    }, [groupings, searchTerm])

    const getFilteredColumns = useCallback(
        (themedColors: any, searchTerm: string, theme: 'light' | 'dark', groupingsCount?: number) => {
          const allColumns = getColumns(themedColors, searchTerm, theme, transformedGroupings.length);
    
          return allColumns.filter((col, idx) => {
            if (col.id === 'name') {
              return true;
            } else {
              const adjustedIndex = idx - 1;
              return !columnList.includes(adjustedIndex);
            }
          });
        },
        [columnList, transformedGroupings.length]
    );

    return (
        <div className={"pb-10"} >
            <div className={'!p-6 tablet-size:!p-0'}>
            <FilterBar
                title={reportName}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                names={names}
                collapse={collapse}
                setCollapse={setCollapse}
                selectedGroupings={selectedGroupings}
                handleBlur={handleBlur}
                actualColumnList={actualColumnList}
                setActualColumnList={setActualColumnList}
                columnList={columnList}
                setColumnList={setColumnList}
                openEditTableDialog={openEditTableDialog}
                setOpenEditTableDialog={setOpenEditTableDialog}
                dialogApplyButton={dialogApplyButton}
                setDialogApplyButton={setDialogApplyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                isEdit={true}
                isAdditionalCurrencyField={true}
                transformedGroupings={transformedGroupings}
                maxSelections={5}
                // for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={fieldsForCSV}
                disabledCSV={!showTable}
                namesEnum={namesEnum}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                csvFilters={csvFilters}
                isCustomFilters={true}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                // SearchRowProps
                isSearch={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
            />
            </div>
            {
                data && data.length !== 0 && showTable && groupings.length !== 0 ? (
                    <ReportTable
                        key={columnList.join(',')}
                        data={data} 
                        initialExpandedDepth={(Number(collapse) - 1)?.toString()}
                        onNoData={setIsNoData} 
                        globalFilter={globalFilter}
                        searchTerm={searchTerm}
                        groupingsCount={transformedGroupings.length}
                        levelNames={transformedGroupings}
                        getColumns={getFilteredColumns}
                        
                    />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                groupings.length === 0 ? 
                                'noFilters' :
                                isNoData ? 'emptySearchResult' :
                                'noData'
                            }
                        />
                    </div>
                )
            }
            {/* <ScrollContainer className="scroll-container" hideScrollbars={false} mouseScroll={{rubberBand: false}} style={{cursor: 'default'}}>
            <div className={`pt-0 tablet-size:pt-6  rounded-3xl ${isMobile && 'relative'} safari-relative`} >
            <div className={'absolute left-0 tablet-size:left-6 z-10 overflow-hidden shadow-[4px_0_8px_-5px_rgba(0,0,0,0.25)] safari-left-zero'} style={{width:(columnWidth as { [key: number]: number })[selectedGroupings]}}>
                    {showTable && filteredData ?
                        (<>
                            <TableContainer style={{overflowX:'hidden'}} >
                                <Table sx={{
                                    "& .MuiTableRow-root th:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                    "& .MuiTableRow-root th:last-of-type": {
                                        borderTopRightRadius: "10px",
                                    },
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none !important"
                                    }
                                }}>
                                    <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"}>
                                        <TableRow>
                                            <TableCell style={{height: 56,fontSize:'12px', position:'sticky', color: themedColors.gray.bluish}}>Groupings</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {GetFirstColumn((filteredData as SalesPaceReportRowInterface)[(namesEnum)[transformedGroupings[0]]], 0)}
                                </Table>
                            </TableContainer>
                        </>)
                        : null}</div>
                <TableContainer className={''} style={{minWidth:(showTable?(3000 - (actualColumnList.length * 70)):0), width:actualColumnList.length>9?'100%':'100%', overflow:'hidden'}}>
                    <Table className={''} sx={{
                        "& .MuiTableRow-root th:first-of-type": {
                            borderTopLeftRadius: "10px",
                        },
                        "& .MuiTableRow-root th:last-of-type": {
                            borderTopRightRadius: "10px",
                        },
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}>
                        <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"} style={{borderRadius: 0}}>
                            <TableRow className={'transaction-report-table-header'}>
                                <TableCell style={{fontSize:'12px', height:56, width: (columnWidth as { [key: number]: number })[selectedGroupings]+10, padding: '16px 8px 16px 8px'}}>{showTable ? 'Groupings' : ''}</TableCell>
                                {showTable && columnHeadings.map((value: {width: string; text:string; minWidth: number, hasSortField: boolean}, index:number) => {
                                    if (!actualColumnList.includes(index)){
                                        return(
                                            <TableCell key={index} style={{height:56, fontSize: '12px', lineHeight: '18px', width: value.width, padding: '10px 8px', minWidth: value.minWidth}} className={`${sortField === fields[value.text as keyof typeof fields] && (sortState !== 0 ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}>
                                                <span
                                                    className={`text-[#677C9F] ${showTable && value.hasSortField ? "cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7]" : ''} flex gap-2 items-center w-full`}
                                                    onClick={showTable && value.hasSortField ? () => sortTableData(fields[value.text as keyof typeof fields], index) : undefined}
                                                    style={{justifyContent: 'flex-end'}}
                                                >
                                                {value.text} {sortField === fields[value.text as keyof typeof fields] && (sortState === 1 ? <SortedArrowAscending /> : sortState === 2 ? <SortedArrowDescending /> : '')}
                                                </span>
                                            </TableCell>
                                        )
                                    }
                                    else
                                        return null;
                                    })}
                            </TableRow>
                        </TableHead>
                        {showTable && filteredData ?
                            (<>
                                {GetTableBody((filteredData as SalesPaceReportRowInterface)[(namesEnum)[transformedGroupings[0 % selectedGroupings]]], 0)}
                            </>)
                            : null}
                    </Table>
                </TableContainer>
                {!showTable ? 
                    <DefaultTableMessage
                        key={loaderKey}
                        tableLoading={tableLoading}
                        state={
                            groupings.length === 0 ? 
                            'noFilters' :
                            !isSearchResult ? 'emptySearchResult' :
                            'noData'
                        }
                    />
                    : null
                }
            </div>
            </ScrollContainer> */}
        </div>
    );
}

export default TransactionReport;
