import React, {useContext, useState} from "react";
import logo from "../../../assets/swiss_logo_new.png";
import "./login.css";
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import {useLoadingContext} from "../../UI/loading/LoadingContext";
import LoadingPopup from "../../UI/loading/LoadingPopup";
import Utils from "../../../odinForgeService/Utils";
import {useNavigate} from "react-router-dom";
import { BackArrowIcon } from "../../../assets/icons/BackArrowIcon";
import ButtonIcon from "../../UI/buttons/ButtonIcon";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import Input from "../../UI/Input/Input";
import DefaultButton from "../../UI/buttons/DefaultButton";
import Logo from "../../../assets/icons/Logo";

export function ForgotPassword() {
    const [email, setEmail] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const loadingContext = useLoadingContext();
    const navigate = useNavigate()
    const { themedColors } = useContext(ThemeContext)!;

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            loadingContext.setLoading(true)
            await OdinForgeService.instance().forgotPassword( email)
                .then((response) => {
                    Utils.instance().onSuccess(response.data.message)
                    navigate('/admin/login')
                })
                .catch((error) => {
                    Utils.instance().onFailure(error.response.data.message)
                })
            loadingContext.setLoading(false)
    }

    const handleNavigateBack = () => {
        navigate('/admin/login')
    }

    return (
        <div
            className={
                "h-auto mt-24 tablet-size:h-full tablet-size:min-h-screen tablet-size:mt-0 flex justify-center tablet-size:justify-normal xl:gap-[14rem] tablet-size:gap-[7rem] tall:h-auto dark:bg-odin-dark-blue-dark"
            }
        >
            {loadingContext.loading && <LoadingPopup loadingText='Loading....'/>}
            <div className="hidden tablet-size:flex items-center left-panel tablet-size h-auto tall:pt-24 tall:pb-24 !z-10">
                <img src={logo} className="p-12" alt=""/>
            </div>
            <div className="flex flex-row h-full tablet-size:mt-auto tablet-size:mb-auto">
                <div
                    className="flex items-center justify-center h-full w-full p-4 s:w-[300px] mobile-size:w-[350px] tablet-size:p-0 tablet-size:w-[480px] tall:pt-24 tall:pb-24">
                    <div className="relative w-full max-w-[445px]">
                        <Logo viewBox="400 250 364 364" />
                        <div>
                            <p style={{color: themedColors.content}}  className="sub-head">Forgot Password?</p>
                            <p style={{color: themedColors.content}} className="sub-description">
                                Please enter your email
                            </p>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div>
                                <Input 
                                    value={email} 
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setEmail(value)
                                        const isEmpty = value === ''
                                        setDisableSubmit(isEmpty)
                                    }} 
                                    type='email' 
                                    label={'Email'} 
                                    name={'email'}
                                    placeholder={'Email'}
                                    isRequired={true}
                                />
                            </div>

                            <div className="flex pt-8">
                                <DefaultButton disabled={disableSubmit} type='submit' label='Reset Password' className="w-full h-11" />
                            </div>
                        </form>
                        <ButtonIcon icon={<BackArrowIcon/>} text={'Back to log in'} onClick={handleNavigateBack} additionalStyle={'mt-5 pl-0 text-[#0079FF]'} />
                    </div>
                </div>
            </div>
        </div>
    );
}