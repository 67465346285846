function transformChunk<T>(chunk: string): T | null {
    const parts = chunk.split(/-----+/).filter(part => part.trim() !== '');

    let accumulatedData: any = {};
    let hasValidData = false;

    for (const part of parts) {
        try {
            const jsonData = JSON.parse(part);

            accumulatedData = mergeData(accumulatedData, jsonData);
            hasValidData = true; 
            
        } catch (error) {
            return null;
        }
    }

    if (!hasValidData) {
        console.log('No valid data in chunk')
        console.warn('No valid data in chunk:', chunk);
        return null;
    }
    return accumulatedData as T;
}

function mergeData(target: any, source: any): any {
    for (const key in source) {
        if (typeof source[key] === 'object' && source[key] !== null && !Array.isArray(source[key])) {
            if (!target[key]) {
                target[key] = {};
            }
            target[key] = mergeData(target[key], source[key]);
        } else if (typeof source[key] === 'number' || typeof source[key] === 'string') {
            if (!target[key]) {
                target[key] = typeof source[key] === 'string' ? '' : 0;
            }
            if (typeof source[key] === 'string' && !isNaN(parseFloat(source[key]))) {
                target[key] = (parseFloat(target[key]) || 0) + parseFloat(source[key]);
            } else {
                target[key] = source[key];
            }
        } else {
            target[key] = source[key];
        }
    }
    return target;
}

export default transformChunk;

export function getCompletedSteps(
    data: any, 
    steps: string[], 
    currentStep: number, 
    namesEnum: { [key: string]: string }
): number {
    let completedStepIndex = currentStep;
    let currentData = data;

    for (let i = currentStep; i < steps.length; i++) {
        const stepKey = namesEnum[steps[i]];
        if (!stepKey) break;

        if (currentData[stepKey]) {
            completedStepIndex = i + 1;
            currentData = currentData[stepKey]
        } else {
            const nestedKeys = Object.keys(currentData);
            let found = false;

            for (const key of nestedKeys) {
                const nestedData = currentData[key];
                if (nestedData && typeof nestedData === 'object' && nestedData[stepKey]) {
                    completedStepIndex = i + 1;
                    currentData = nestedData[stepKey];
                    found = true;
                    break;
                }
            }

            if (!found) break;
        }
    }

    return completedStepIndex;
}





