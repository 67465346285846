import { useNavigate, useLocation } from "react-router-dom";
import { TenantUrlsStaging } from "../odinForgeService/Enums/tenantUrls";
import { TenantUrls } from "../odinForgeService/Enums/tenantUrls";
import { getCookie } from "../helpers/getCookie";

interface UseTenantRedirectParams {
  isSuperAdmin?: boolean;
  isProfile?: boolean;
  isLoginPage?: boolean;
}

const useTenantRedirect = ({ isSuperAdmin = false, isProfile = false, isLoginPage = false }: UseTenantRedirectParams) => {
  console.log('useTenantRedirect');
  console.log('isSuperAdmin', isSuperAdmin);
  console.log('isProfile', isProfile);
  console.log('isLoginPage', isLoginPage);

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToAppropriateUrl = () => {
    console.log('redirectToAppropriateUrl');
    const url = process.env.REACT_APP_BASE_URL;
    console.log('url', url);
    const queryParams = new URLSearchParams(location.search);
    console.log('queryParams', queryParams);
    const tenant = queryParams.get("tenant") || getCookie('tenant');
    console.log('tenant', tenant);
    const isSuperAdminFromQuery = queryParams.get("admin") || getCookie('admin');
    console.log('isSuperAdminFromQuery', isSuperAdminFromQuery);

    if (isSuperAdmin || isSuperAdminFromQuery === "1") {
        console.log('isSuperAdmin || isSuperAdminFromQuery === "1"');
        if (isProfile) {
            console.log('isProfile navigate /dashboard/profileInfo?${queryParams.toString()');
            navigate(`/dashboard/profileInfo?${queryParams.toString()}`);
        } else {
            console.log('else navigate /admin/login?${queryParams.toString()');
            navigate(`/admin/login?${queryParams.toString()}`);
        }
    } else if (url === "https://reports-api.swisspriv.com") {
      console.log('url === "https://reports-api.swisspriv.com"');
      if (tenant && tenant in TenantUrlsStaging) {
        console.log('tenant && tenant in TenantUrlsStaging', TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging]);
        if (isProfile) {
          console.log('isProfile navigate TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging] + account');
          window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging] + 'account';
        } else {
          console.log('else navigate TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging]');
          window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging];
        }
      } else if (!isLoginPage) {
        console.log('!isLoginPage navigate /');
        window.location.href = "/";
      }
    } else {
      console.log('else');
      if (tenant && tenant in TenantUrls) {
        console.log('tenant && tenant in TenantUrls', TenantUrls[tenant as keyof typeof TenantUrls]);
        if (isProfile) {
          console.log('isProfile navigate TenantUrls[tenant as keyof typeof TenantUrls] + account');
          window.location.href = TenantUrls[tenant as keyof typeof TenantUrls] + 'account';
        } else {
          console.log('else navigate TenantUrls[tenant as keyof typeof TenantUrls]');
          window.location.href = TenantUrls[tenant as keyof typeof TenantUrls];
        }
      } else if (!isLoginPage) {
        console.log('!isLoginPage navigate /');
        window.location.href = "/";
      }
    }
  };

  console.log('end of useTenantRedirect');

  return { redirectToAppropriateUrl };
};

export default useTenantRedirect;

// i have this function here because we still have class based components that use this logic
export function handleTenantRedirect() {  
    console.log('handleTenantRedirect');
    const urlParams = new URLSearchParams(window.location.search);
    console.log('urlParams', urlParams);
    const tenant = urlParams.get("tenant") || getCookie('tenant');
    console.log('tenant', tenant);
    const isSuperAdmin = urlParams.get("admin") || getCookie('admin');
    console.log('isSuperAdmin', isSuperAdmin);
    const url = process.env.REACT_APP_BASE_URL;
    console.log('url', url);
  
    if (isSuperAdmin === "1") {
      console.log('isSuperAdmin === "1" navigate /admin/login?${urlParams.toString()');
      window.location.href = `/admin/login?${urlParams.toString()}`;
    } else if (url === "https://reports-api.swisspriv.com") {
      console.log('url === "https://reports-api.swisspriv.com"');
      if (tenant && tenant in TenantUrlsStaging) {
        console.log('tenant && tenant in TenantUrlsStaging', TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging]);
        window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging];
      } else {
        console.log('else navigate /');
        window.location.href = "/";
      }
    } else {
      console.log('else');
      if (tenant && tenant in TenantUrls) {
        console.log('tenant && tenant in TenantUrls', TenantUrls[tenant as keyof typeof TenantUrls]);
        window.location.href = TenantUrls[tenant as keyof typeof TenantUrls];
      } else {
        console.log('else navigate /');
        window.location.href = "/";
      }
    }
    console.log('end of handleTenantRedirect');
}


