import React from 'react';
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CustomerDetails, DuplicateCustomerReportData } from "../types/duplicate-customer-report.types";
import VerticalProductStatus from '../components/VerticalProductStatus';

dayjs.extend(utc);
dayjs.extend(timezone);

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D') },
    4: { startDate: dayjs.tz().endOf('D').subtract(30, "d"), endDate: dayjs.tz().endOf('D') },
    5: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('w') },
    6: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('M') },
    7: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('y') },
    8: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
    9: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    10: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('D').subtract(1, "d").tz(timezone), endDate: dayjs.tz().endOf('D').subtract(1, "d").tz(timezone) },
        3: { startDate: dayjs.tz().endOf('D').subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().endOf('D').subtract(30, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        5: { startDate: dayjs.tz().startOf('w').tz(timezone), endDate: dayjs.tz().endOf('w').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('M').tz(timezone), endDate: dayjs.tz().endOf('M').tz(timezone) },
        7: { startDate: dayjs.tz().startOf('y').tz(timezone), endDate: dayjs.tz().endOf('y').tz(timezone) },
        8: { startDate: dayjs.tz().startOf('w').subtract(1, "w").tz(timezone), endDate: dayjs.tz().endOf('w').subtract(1, "w").tz(timezone) },
        9: { startDate: dayjs.tz().startOf('M').subtract(1, "M").tz(timezone), endDate: dayjs.tz().startOf('M').subtract(1, "s").tz(timezone) },
        10: { startDate: dayjs.tz().startOf('y').subtract(1, "y").tz(timezone), endDate: dayjs.tz().startOf('y').subtract(1, "s").tz(timezone) }
    };
};

export const dateRangeListCustom = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

const columnHelperMultiple = createColumnHelper<DuplicateCustomerReportData>()

export const tableColumnsMultiple = [
    columnHelperMultiple.accessor('customer_id', {
        header: 'Customer ID',
        cell: ({ getValue }) => {
            const customerId = getValue();
            return (
                <a href={`https://dashboard.swisscrm.com/customer/${customerId}`} target="_blank" rel="noopener noreferrer" className='hover:!text-odin-light-blue-medium'>
                    {customerId}
                </a>
            );
        },
        meta: {
            isFirstColumn: true,
            isSearch: true,
        }
    }),
    columnHelperMultiple.accessor('number_of_duplicate_txns', {
        header: 'Txns Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
            isToolTip: true,
            tooltipMessage: 'Number of txn attempts made by the customer'
        }
    }),
    columnHelperMultiple.accessor('number_of_duplicate_initial_sales', {
        header: 'Initial Sales Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
            isToolTip: true,
            tooltipMessage: 'Number of initial successful sales made by the customer'
        }
    }),
    columnHelperMultiple.accessor('number_of_duplicate_reproc_sales', {
        header: 'Reproc',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
            isToolTip: true,
            tooltipMessage: 'Number of successful reproc sales made by the customer'
        }
    }),
    columnHelperMultiple.accessor('number_of_duplicate_rebill_sales', {
        header: 'Rebill Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
            isToolTip: true,
            tooltipMessage: 'Number of successful rebill sales made by the customer'
        }
    }),
    columnHelperMultiple.accessor('product_vertical_is_same', {
        header: 'Product Vertical',
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                <>
                    {
                        value || value === 0 ? value === 1 ? (
                            <VerticalProductStatus level={1} />
                        ) : (
                            <VerticalProductStatus level={0} />
                        ) : '---'
                    }
                </>
            )
        },
        meta: {
            isAlignTextRight: true,
            isToolTip: true,
            tooltipMessage: 'If "Yes", duplicate orders are from the same vertical. If "No", orders from the customer are from different verticals'
        }
    }),
];

const subColumnHelperMultiple = createColumnHelper<CustomerDetails[]>()

export const subTableColumnsMultiple = [
    subColumnHelperMultiple.accessor('transacted_at_date', {
        header: 'Customer ID',
        cell: ({ getValue }) => getValue(),
    }),
    subColumnHelperMultiple.accessor('number_of_duplicate_txns', {
        header: 'Txns Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    subColumnHelperMultiple.accessor('number_of_duplicate_initial_sales', {
        header: 'Initial Sales Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    subColumnHelperMultiple.accessor('number_of_duplicate_reproc_sales', {
        header: 'Reproc',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    subColumnHelperMultiple.accessor('number_of_duplicate_rebill_sales', {
        header: 'Rebill Duplicate',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    subColumnHelperMultiple.accessor('product_vertical_is_same', {
        header: 'Product Vertical',
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                <>
                    {
                        value || value === 0 ? value === 1 ? (
                            <VerticalProductStatus level={1} />
                        ) : (
                            <VerticalProductStatus level={0} />
                        ) : '---'
                    }
                </>
            )
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
];


