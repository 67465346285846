import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";
import { getFontColor } from '../../../../../util';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid (C1)',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid (C1)': 'c1',
};

export const transformNames = [
    'currency',
    'geo',
    'affiliate_name',
    'mid_name',
    'funnel_name',
    'c1',
  ];

export const fields = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_into_orders",
    "Partials": "partials",
    "Partial rate": "partial_rate",
    "Partial Conversion rate": "partial_conv_rate",
    "Conversion rate": "conv_rate",
    "Sales": "sales",
    "Upsells": "upsells",
    "Take rate (Upsell rate)": "take_rate",
    "Declines": "declines",
    "Approval %": "approval",
} as const;

export const fieldsForCSV = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_into_orders",
    "Partials": "partials",
    "Partial rate": "partial_rate",
    "Partial Conversion rate": "partial_conv_rate",
    "Conversion rate": "conv_rate",
    "Sales": "sales",
    "Upsells": "upsells",
    "Take rate (Upsell rate)": "take_rate",
    "Declines": "declines",
    "Approval %": "approval",
};

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D')},
    4: { startDate: dayjs.tz().endOf('D').subtract(30, "d"), endDate: dayjs.tz().endOf('D')},
    5: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('D') },
    6: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
    7: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('D') },
    8: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().startOf('w').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    10: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
        3: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(30, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, 
        5: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, 
        6: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        7: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        8: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().startOf('week').subtract(1, "second").tz(timezone) },
        9: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
        10: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
};

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(350 + ((Number(groupingsCount)) * 8)) - ((row.depth + 1) * 32)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.clicks,
            id: 'Clicks',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Clicks',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.clicks_into_orders,
            id: 'Order Clicks',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Order Clicks',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partials,
            id: 'Partials',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Partials',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partial_rate,
            id: 'Partial rate',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Partial rate',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partial_conv_rate,
            id: 'Partial Conversion rate',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Partial Conversion rate',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.conv_rate,
            id: 'Conversion rate',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Conversion rate',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.sales,
            id: 'Sales',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Sales',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.upsell,
            id: 'Upsells',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Upsells',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.take_rate,
            id: 'Take rate (Upsell rate)',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Take rate (Upsell rate)',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Declines / (Declines + Sales)'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.declines,
            id: 'Declines',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Declines',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.approval,
            id: 'Approval %',
            cell: ({ getValue }) => {
                const value = getValue();
                const color = getFontColor(value as number);
                return <span style={{color}}>{Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%'}</span>
            },
            header: 'Approval %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
        ]
}