import Cookies from 'js-cookie';

export const setCookie = (name: any, value: any) => {
    Cookies.set(name, value);
  };

export const setGlobalCookie = (name: string, value: string, expiresInDays = 30) => {
    Cookies.set(name, value, {
      expires: expiresInDays, // Срок действия куки
      path: '/', // Доступно на всех путях
      domain: '.swisscrm.com', // Установить для всех субдоменов
      secure: true, // Только через HTTPS
      sameSite: 'None', // Для передачи между субдоменами
    });
};