
import React, { useContext, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  Cell,
} from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import Tooltip from '@mui/material/Tooltip';
import PaginationComponent from '../../../../molecules/PaginationComponent/PaginationComponent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import SortedArrowAscending from '../../../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../../../assets/icons/SortedArrowDescending';
import { Icon } from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { subColumns, tableColumns, subColumnsPartner, tableColumnsPartner } from '../utils/config';
import { transformData } from '../utils/transformData';
import { CustomColumnMeta, VarianceReporHourData, VarianceReporPartnerData, VarianceReportData } from '../utils/types/variance-report.types';
import ExclamationMark from '../../../../../assets/icons/ExclamationMark';

interface VarianceReportTableProps {
  data: VarianceReportData[];
  getDefault(): React.JSX.Element;
  viewMode?: string;
  setTransformedTableDataCSV: React.Dispatch<React.SetStateAction<(VarianceReporHourData | VarianceReporPartnerData)[]>>
  searchTerm: string;
}

const VarianceReportTable = ({ data, getDefault, viewMode = 'affiliates', setTransformedTableDataCSV, searchTerm }: VarianceReportTableProps) => {
  const { themedColors, theme } = useContext(ThemeContext)!;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });
  const [hoveredTooltipId, setHoveredTooltipId] = useState<string | null>(null);

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const transformedData = React.useMemo(() => {
    setTransformedTableDataCSV(transformData(data, viewMode));
    return transformData(data, viewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, viewMode]);

  const columns = viewMode === 'affiliates' ? tableColumnsPartner(searchTerm, theme) : tableColumns;
  const subCols = viewMode === 'affiliates' ? subColumnsPartner : subColumns(searchTerm, theme);

  const table = useReactTable({
    data: transformedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  // @ts-ignore
  const getCellValue = (subRow, accessorKey) => {
    if (accessorKey === 'hour') {
      return subRow.hour;
    }
  
    const keys = accessorKey.split('.');
    let value = subRow;
  
    for (const key of keys) {
      value = value?.[key];
      if (value === undefined) {
        return ''; // Если значение отсутствует, вернуть пустую строку
      }
    }
  
    return value;
  };

  return (
    <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
      {transformedData.length > 0 ? (
        <>
          <ScrollContainer
            className='scroll-container'
            hideScrollbars={false}
            mouseScroll={{ rubberBand: false }}
            style={{ cursor: 'default', borderRadius: !isMobile ? 8 : 0}}
          >
            <table
              className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
              style={{ minWidth: 1300 }}
            >
              <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const isFontBold = (header.column.columnDef.meta as CustomColumnMeta)?.isFontBold;
                      const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';
                      const isAlignTextRight = (header.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                      const isBorderRight = (header.column.columnDef.meta as CustomColumnMeta)?.isBorderRight;
                      const isBorderLeft = (header.column.columnDef.meta as CustomColumnMeta)?.isBorderLeft;
                      const isToolTip = (header.column.columnDef.meta as CustomColumnMeta)?.isToolTip;
                      const tooltipMessage = (header.column.columnDef.meta as CustomColumnMeta)?.tooltipMessage;

                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            borderRight: isBorderRight ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                            borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                            borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            color: themedColors.gray.bluish,
                            fontWeight: isFontBold ? '600' : '500',
                          }}
                          className={`${headerBackground}`}
                        >
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                            className={`font-inherit text-left ${
                              header.column.getCanSort() ? 'cursor-pointer' : ''
                            } 
                              !h-[56px] !py-0 !px-2 flex justify-start items-center`}
                            style={{
                              justifyContent: isAlignTextRight ? 'right' : 'left',
                              fontSize: '12px',
                              lineHeight: '14.52px',
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <span className='ml-2'><SortedArrowAscending /></span>,
                              desc: <span className='ml-2'><SortedArrowDescending /></span>,
                            }[header.column.getIsSorted() as string] ?? null}
                            {
                              isToolTip && (
                                <Tooltip 
                                  classes={{ 
                                    tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal !bg-opacity-90 !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                    arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                  }}
                                  title={tooltipMessage}
                                  placement="right-end"
                                  onOpen={() => setHoveredTooltipId(header.id)}
                                  onClose={() => setHoveredTooltipId(null)} 
                                >
                                  <span className='relative top-[-1px] left-[5px]'>
                                    <ExclamationMark isTipsHovered={hoveredTooltipId === header.id} />
                                  </span>
                                </Tooltip>
                              )
                            }
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <tr key={row.id} style={{ backgroundColor: 'inherit' }} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                      {row.getVisibleCells().map((cell) => {
                        function getIsTooltop(cell: Cell<VarianceReporHourData | VarianceReporPartnerData, unknown>) {
                          if (!cell.getValue()) return '';
                          const cellWidth = cell.column.getSize();
                          const charCount = Math.floor(cellWidth! / 8);
                          return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                        }

                        const isFirstColumn = (cell.column.columnDef.meta as CustomColumnMeta)?.isFirstColumn;
                        const isBorderLeft = (cell.column.columnDef.meta as CustomColumnMeta)?.isBorderLeft;
                        const isAlignTextRight = (cell.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                        const isLastInGroup = (cell.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                        
                        const isColored = (cell.column.columnDef.meta as CustomColumnMeta)?.isColored;
                        let color = themedColors.content
                        if (isColored) {
                          const value = flexRender(cell.column.columnDef.cell, cell.getContext());
                          if (typeof value === 'object' && value !== null && 'props' in value) {
                            const val = value.props.getValue() as string;
                            if (val !== '---') {
                                if (Number(val) > 0) {
                                  color = '#2AA300'
                                } else if (Number(val) < 0) {
                                  color = '#FF3B30'
                                } else {
                                  color = themedColors.content
                                }
                            }
                          }
                        }


                        return (
                          <td
                            key={cell.id}
                            id={cell.id}
                            className='!text-sm !h-[42px] !py-0 !font-normal'
                            style={{
                              borderBottom: row.getIsExpanded()
                                ? ''
                                : theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                              color: !isColored ? themedColors.content : color,
                              minWidth: '200px',
                              maxWidth: '300px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              backgroundColor: row.getIsExpanded() ? theme === 'light' ? '#DDEDFD' : '#313E56' : 'inherit',
                              borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #353E50' : '',
                              cursor: isFirstColumn ? 'pointer' : 'default',
                              borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)' : undefined,
                              paddingLeft:  8,
                              paddingRight: 8,
                              textAlign: isAlignTextRight ? 'right' : 'left'
                            }}
                            onClick={isFirstColumn ? () => row.toggleExpanded() : undefined}
                          >
                            <Tooltip
                              classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                              title={getIsTooltop(cell) as string}
                              placement="left"
                              arrow
                            >
                              <span className='flex items-center' style={{justifyContent: isAlignTextRight ? 'right' : 'left', fontWeight: isFirstColumn ? '500' : '400',fontSize: '12px', }}>
                                { isFirstColumn ? (row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}/> : <Icon icon={ChevronRightIcon} style={{color:themedColors.content}}/>) : '' }
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </span>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                    {row.getIsExpanded() &&
                    // @ts-ignore
                      row.original[`${viewMode === 'affiliates' ? 'hours' : 'partners'}`]?.map((subRow, index) => {
                        return (
                          <tr key={index} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F] bg-[#F5FAFF] dark:bg-[#202939]'>
                            {subCols.map((subColumn) => {
                              const isBorderLeft = (subColumn.meta as CustomColumnMeta)?.isBorderLeft;
                              const isAlignTextRight = (subColumn.meta as CustomColumnMeta)?.isAlignTextRight;
                              const isFirstColumn = (subColumn.meta as CustomColumnMeta)?.isFirstColumn;

                              const cellContext = {
                                getValue: () => getCellValue(subRow, subColumn.accessorKey),
                                row: {
                                  original: subRow,
                                },
                                column: subColumn,
                              };

                              const isColored = (subColumn.meta as CustomColumnMeta)?.isColored;
                              let color = themedColors.content
                              if (isColored) {
                                const value = flexRender(subColumn.cell as any, cellContext);
                                if (typeof value === 'object' && value !== null && 'props' in value) {
                                  const val = value.props.getValue() as string;
                                  if (val !== '---') {
                                      if (Number(val) > 0) {
                                        color = '#2AA300'
                                      } else if (Number(val) < 0) {
                                        color = '#FF3B30'
                                      } else {
                                        color = themedColors.content
                                      }
                                  }
                                }
                              }

                              return (
                                <td
                                  key={subColumn.id}
                                  className='!text-sm !h-[42px] !py-0 !font-normal'
                                  style={{
                                    borderBottom: theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                                    color: !isColored ? themedColors.content : color,
                                    maxWidth: 300,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    paddingLeft: subColumn.accessorKey === 'hour' || subColumn.accessorKey === 'partner' ? 49 : 8,
                                    borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #DFE9FE' : '1px solid #353E50' : '',
                                    paddingRight: 8,
                                    textAlign: isAlignTextRight ? 'right' : 'left',
                                  }}
                                >
                                  <Tooltip
                                    classes={{
                                      tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                      arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                    }}
                                    title={""}
                                    placement="left-start"
                                    arrow
                                  >
                                    <span style={{fontSize: '12px',lineHeight: '16.94px', fontWeight: isFirstColumn ? '500' : '400'}}>
                                      {flexRender(subColumn.cell as any, cellContext)}
                                    </span>
                                  </Tooltip>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </ScrollContainer>
          {/* Pagination */}

          <PaginationComponent
            firstPage={table.firstPage}
            previousPage={table.previousPage}
            getCanPreviousPage={table.getCanPreviousPage}
            getCanNextPage={table.getCanNextPage}
            nextPage={table.nextPage}
            lastPage={table.lastPage}
            getPageCount={table.getPageCount}
            pageIndex={table.getState().pagination.pageIndex}
            setPageIndex={table.setPageIndex}
            setPageSize={table.setPageSize}
            pageSize={table.getState().pagination.pageSize}
            getRowCount={table.getRowCount}
          />
        </>
      ) : (
        getDefault()
      )}
    </div>
  );
};

export default VarianceReportTable;
