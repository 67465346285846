// import { v4 as uuidv4 } from 'uuid';
// import { AffiliateReportData } from '../types/affiliate-report.types';

// export const transformData = (data: AffiliateReportData) => {
//   if (Object.keys(data).length === 0) {
//     return {};
//   }

//   const transformObject = (obj: any, objName: any) => {
//     // Extract sales and clicks as numbers
//     const sales = Number(obj.stats.sales);
//     const clicks = Number(obj.stats.clicks);

//     // Compute conv_percentage1
//     const conv_percentage =
//       sales && clicks
//         ? `${((sales / clicks) * 100).toFixed(2)}`
//         : '0.00';

//     // Include conv_percentage1 in the transformed object
//     const transformed = {
//       ...obj.stats,
//       conv_percentage,
//       name: objName,
//       id: uuidv4(),
//     };

//     Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
//       if (key !== 'stats') {
//         if (typeof value === 'object' && !Array.isArray(value)) {
//           transformed[key] = Object.entries(value)
//             .sort(
//               (a: any, b: any) =>
//                 (b[1].stats?.clicks || 0) - (a[1].stats?.clicks || 0)
//             )
//             .map(
//               ([nestedKey, nestedValue]: [nestedKey: any, nestedValue: any]) => {
//                 if (nestedKey === '') {
//                   return transformObject(nestedValue, 'NaN');
//                 }
//                 return transformObject(nestedValue, nestedKey);
//               }
//             );
//         } else {
//           transformed[key] = value;
//         }
//       }
//     });
//     return transformed;
//   };

//   const result: { [key: string]: any } = {};
//   Object.entries(data).forEach(
//     ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
//       result[mainKey] = Object.entries(mainValue)
//         .sort(
//           (a: any, b: any) =>
//             (b[1].stats?.clicks || 0) - (a[1].stats?.clicks || 0)
//         )
//         .map(([nestedKey, nestedValue]) => {
//           if (nestedKey === '') {
//             return transformObject(nestedValue, 'NaN');
//           }
//           return transformObject(nestedValue, nestedKey);
//         });
//     }
//   );
//   return result;
// };

import { TransformedAffiliateReportData, StatsInterface } from "../types/affiliate-report.types";
import { transformNames } from "./config";
import { v4 as uuidv4 } from 'uuid';

export const prepareDataForTransform = (data: any) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value)
              .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
              .map(
                ([nestedKey, nestedValue]) => {
                  if (nestedKey === "") {
                    return transformObject(nestedValue, "NaN");
                  }
                  return transformObject(nestedValue, nestedKey);
                }
              );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue)
          .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
          .map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
      }
    );
    return result;
};

export function transformData(data: any): TransformedAffiliateReportData[] | [] {
    const preparedData = prepareDataForTransform(data);
    function recursiveTransform(node: any): TransformedAffiliateReportData[] {
        if (Array.isArray(node)) {
          // Process each item in the array and collect the results
          const transformedArray = node.flatMap(item => recursiveTransform(item));
          // Sort the array here based on your sorting criteria
          // For example, sort by stats.clicks in descending order
          transformedArray.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));
          return transformedArray;
        } else if (typeof node === 'object' && node !== null) {
        const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

        if (nameKey) {
            const name = node.name ?? '---';

            const stats: StatsInterface = {
              name,
              clicks: node.clicks || null,
              clicks_with_orders: node.clicks_with_orders || null,
              partials: node.partials || null,
              partials_percentage: node.partials_percentage || null,
              declines: node.declines || null,
              declines_percentage: node.declines_percentage || null,
              sales: node.sales || null,
              conv_percentage: node.conv_percentage || null,
              partial_conv: node.partial_conv || null,
              sales_conv: node.sales_conv || null,
              sales_rev: node.sales_rev || null,
              currency: node.currency || null,
            };

            const child_data: TransformedAffiliateReportData[] = [];

            for (const key in node) {
              if (transformNames.includes(key) && key !== nameKey) {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              } else if (typeof node[key] === 'object') {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              }
            }

            child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

            return [{
              name,
              stats,
              child_data,
            }];
        } else {
            const child_data: TransformedAffiliateReportData[] = [];

            for (const key in node) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
            }

            child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

            return child_data;
        }
        }
        return [];
    }

    return recursiveTransform(preparedData);
}

