import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { BinRoutingPerformaceReportClonedData, BinRoutingPerformaceReportResponse, BinRoutingReportData } from "./types/bin-routing-performace-report.types";
import PaginationState from "./types/pagination.types";
import BinRoutingPerformaceReportTable from "./components/BinRoutingPerformaceReportTable";
import FilterBarBinRoutingPerformanceReport from "../../../organism/filterbar/FilterBarBinRoutingPerformanceReport";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { DataRangesParamsList, dateRangeList } from "../../../../types/enums/DataRangesParamsList";
import sanitizeInput from "../../../../helpers/sanitizeInput";

dayjs.extend(utc);
dayjs.extend(timezone);


interface ConversationReportInterface {
    clonedData: BinRoutingPerformaceReportClonedData
    passData: (data: BinRoutingPerformaceReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const BinRoutingPerformaceReport: React.FC<ConversationReportInterface> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(false);
    const [freezeButton, setFreezeButton] = React.useState(false)
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const [data, setData] = React.useState<BinRoutingReportData[]>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.BinRoutingPerformaceReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);

    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<BinRoutingReportData[]>([]);
    const [transformedNamesEnumCSV, setTransformedNamesEnumCSV] = useState<{ [key: string]: string }>({});

    const reportConfigValues = useRef({ reportName });

    const [loaderKey, setLoaderKey] = useState(0);

    const isUserInitiated = useRef(false);

    const [isFirstRender, setIsFirstRender] = React.useState(true);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
    })

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);

    const [csvFilters, setCsvFilters] = useState<string[]>([])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate
        )) {
            previousValuesRef.current = {
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportName, dateRange, startDate, endDate]);

    function generateParams() {
        const params = new URLSearchParams();

        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));

        params.append('currentPage', pagination.pageIndex ? pagination.pageIndex?.toString() : '1');
        params.append('pageSize', pagination.pageSize ? pagination.pageSize?.toString() : '10');

        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
                superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
                tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }    

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

        params.append('search', sanitizeInput(localStorage.getItem('searchTerm')?.trim() ?? ''));

        return params;
    }

    function generateObjectToHash(){
        const dataToHash :object = {
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
        }

        return dataToHash;
    }

    async function handleBlur(isRefresh?: boolean) {
        setTableLoading(true);
        if (isRefresh) {} else {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
        const params = generateParams()
        setData([]);
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            OdinForgeService.instance().getBinRoutingPerformanceReportData(params, clonedReportId)
                .then(async (result: {data: BinRoutingPerformaceReportResponse}) => {
                    setTableLoading(false);

                    if (result && result?.data){

                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: result.data.current_page,
                            pageSize: result.data.per_page,
                            totalPages: result.data.last_page,
                            totalItems: result.data.total,
                        }));
                        setData(result.data.data);

                        createHash(generateObjectToHash, result.data.data, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                        // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
            }
    }

    const handlePageChange = (newPageIndex: number) => {
        isUserInitiated.current = true;
        setPagination((prev) => ({
          ...prev,
          pageIndex: newPageIndex + 1,
        }));
    };

    const handlePageSizeChange = (newPageSize: number) => {
        isUserInitiated.current = true;
        setPagination((prev) => ({
          ...prev,
          pageIndex: 1,
          pageSize: newPageSize,
        }));
    };

    function generateConfigRequestBody() {
        return {
            filters: {
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
        }
        passData(dataToPass)
    }

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
        isUserInitiated.current = true; 
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant]);

    function updatePaginationOptions() {
        isUserInitiated.current = true;
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
    }

    const handleSearch = (isReset = false) => {
        localStorage.setItem('searchTerm', searchTerm);
        if (!isReset) {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
    
        setIsSearchTriggered(true);
    
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
        }));
    };

    useEffect(() => {
        setCsvFilters(Array.from(new Set([
            ...(searchTerm ? [`search-${searchTerm}`] : ['-']), 
        ])))
    }, [searchTerm])
    
    useEffect(() => {
        if (isSearchTriggered) {
            handleBlur(true);
            setIsSearchTriggered(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchTriggered]);

    // Temporarly it should be last useEffect
    useEffect(() => {
        if (isFirstRender) {
            localStorage.removeItem('searchTerm');
            setIsFirstRender(false);
            return;
        }
      
        if (isUserInitiated.current) {
          isUserInitiated.current = false; // Reset the flag
          handleBlur(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize, isUserInitiated.current]);

    return (
        <div className={"pb-10"} >
            <div className="!p-6 tablet-size:!p-0">
            <FilterBarBinRoutingPerformanceReport
                handleBlur={updatePaginationOptions}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                title={ReportName.BinRoutingPerformaceReport}
                //////////////////////// for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={transformedNamesEnumCSV}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={transformedNamesEnumCSV}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                csvFilters={csvFilters}
                isCustomFilters={true}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                // SearchRowProps
                isSearch={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
            />
            </div>
            {
                data && data.length !== 0 && pagination ? (
                    <BinRoutingPerformaceReportTable 
                        data={data} 
                        onPageChange={handlePageChange} 
                        paginationState={pagination} 
                        onPageSizeChange={handlePageSizeChange} 
                        setTransformedTableDataCSV={setTransformedTableDataCSV} 
                        setTransformedNamesEnumCSV={setTransformedNamesEnumCSV} 
                        searchTerm={searchTerm}
                    />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                'noData'
                            }
                        />
                    </div>
                )
            }
            
        </div>
    )
}

export default BinRoutingPerformaceReport