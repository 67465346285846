import getSymbolFromCurrency from 'currency-symbol-map';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import { SalesPerformanceReportData, SalesPerformanceReportRowInterface } from '../types/sales-performance-report.types';

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(data: SalesPerformanceReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
    const result: { [key: string]: string }[] = [];
    const groupingLevels = new Set<number>();
    const filtersList = ['Currency', ...filters!]

    function processItem(item: SalesPerformanceReportRowInterface, hierarchy: string[] = [], level: number = 0) {
      const baseItem: { [key: string]: string } = {
        clicks: item?.clicks?.toLocaleString() || '',
        clicks_into_orders: Number(item?.clicks_into_orders)?.toLocaleString() || '',
        partials: Number(item?.partials)?.toLocaleString() || '',
        partial_rate: parseFloat(Number(item?.partial_rate)?.toFixed(2))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' || '',
        partial_conv_rate: parseFloat(Number(item?.partial_conv_rate)?.toFixed(2))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' || '',
        conv_rate: parseFloat(Number(item?.conv_rate)?.toFixed(2))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' || '',
        sales: Number(item?.sales)?.toLocaleString() || '',
        upsells: Number(item?.upsell)?.toLocaleString() || '',
        take_rate: parseFloat(Number(item?.take_rate)?.toFixed(2))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' || '',       
        declines: Number(item?.declines)?.toLocaleString() || '',
        approval: parseFloat(Number(item?.approval)?.toFixed(2))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' || '',
      };

      if (item.name || hierarchy.length > 0) {
        groupingLevels.add(level);
      }

      const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

      baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

      result.push(baseItem);

      Object.keys(namesEnum).forEach((key) => {
        const nestedKey = namesEnum[key];
        if (Array.isArray(item[nestedKey])) {
          item[nestedKey].forEach((nestedItem: SalesPerformanceReportRowInterface) =>
            processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
          );
        }
      });
    }

    if (data.currency && Array.isArray(data.currency)) {
      // @ts-ignore
      data.currency.forEach((currency) => processItem(currency));
    }

    const groupingFields = Array.from(groupingLevels).map((level) => {
      const foundKey = getKeyByValue(namesEnum, filtersList[level]);
      return foundKey ?? filtersList[level];
    });

    return result.map((item) => {
      const transformedItem: { [key: string]: string } = {};
      groupingFields.forEach((field) => {
        if (field === 'Currency' && item[field]) {
          transformedItem[field] = `${item[field]?.toUpperCase()} - ${getSymbolFromCurrency(item[field])}`;
        } else {
          transformedItem[field] = item[field] || '';
        }
      });
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[fieldsForCSV[key]];
      });
      return transformedItem;
    });
  }

  export default transformDataToCSVFormat;