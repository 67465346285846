import { ChevronRightIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { flexRender } from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/react-table';
import { Table } from '@tanstack/react-table';
import React from 'react'
import {Icon} from "@tremor/react";
import { darkSubColorEnum, subColorEnum } from '../../../types/enums/TableColors';

interface AdditionalRowsProps {
    row: any;
    table: Table<any>;
    themedColors: any;
    theme: 'light' | 'dark';
    columns: ColumnDef<any>[];
    rowType: 'total' | 'subscriptions' | 's2' | 's1';
    isMobile: boolean;
    index: number;
    backgroundColor: string;
  }

  const AdditionalRow = ({ 
    row, 
    table, 
    themedColors, 
    theme, 
    columns, 
    rowType, 
    isMobile, 
    index,
    backgroundColor
  }: AdditionalRowsProps) => {
    return (
    <>
    <tr key={row.id + index + rowType} 
        className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'
        style={{
          background: backgroundColor,
          opacity: 1
        }}
      >
        {columns.map((column: any, index: number) => {
                            
          const isLastInGroup = (column.meta as any)?.isLastInGroup;
          const isAlignTextRight = (column.meta as any)?.isAlignTextRight;
          const isFirstColumn = (column.meta as any)?.isFirstColumn;

          const isPinned = (column.meta as any)?.isPinned

          const getValue = () => {
            if (column.accessorFn) {
              return column.accessorFn(row);
            } else if (column.accessorKey) {
              return row[column.accessorKey];
            } else {
              return undefined;
            }
          };
                          
          const cellContext = {
            getValue,
            row,
            column,
            table,
          };
          return (
            <React.Fragment key={'row' + column.id + index + rowType}>
                <td
                    key={'row' + column.id + index + rowType}
                    id={column.id}
                    className={`
                        !text-xs !h-[42px] !py-0 !px-2 !font-normal 
                        ${isPinned ? theme === 'light' ? 'is-sticky' : 'is-sticky-dark' : ''}
                    }`}
                    style={{
                        borderBottom: theme === 'light' ? '1px solid #e0e0e0' : '1px solid #353E50',
                        borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid #353E50' : undefined,
                        color: themedColors.content,
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: isAlignTextRight ? 'right' : 'left',
                        zIndex: isPinned ? 1 : 0,
                        boxShadow:isPinned ? '-4px 0 4px -4px gray inset' : '',
                        opacity: isPinned ? 1 : 1,
                        position: isPinned && !isMobile ? 'sticky' : 'relative',
                        left: isPinned && !isMobile ? `-1px` : '',
                        right: isPinned && !isMobile ? `-1px` : '',
                    }}
                >
                    {
                        isPinned && Array.from({ length: row.depth }, (_, i) => (
                            <span key={i + rowType} className='block'
                                style={{
                                    background: theme === 'light' ? subColorEnum[i] : darkSubColorEnum[i],
                                    width: '2rem',
                                    position: 'absolute',
                                    height: '45px',
                                    left: `${-1 + (i *32)}px`,
                                    top: '-5px'
                                }}
                            ></span>
                        ))
                    }
                    <span 
                        className='relative flex items-center cursor-pointer'
                        style={{justifyContent: isAlignTextRight ? 'right' : 'left', fontWeight: isFirstColumn ? '500' : '400',fontSize: '12px', }} 
                        onClick={isFirstColumn ? () => row.toggleExpanded() : undefined}
                    >                                  
                        { isFirstColumn ? 
                          (row.getIsExpanded() ? 
                            <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' /> : 
                            <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />) : 
                             '' }
                            {
                                flexRender(column.cell, cellContext)
                            }
                        </span>
                    </td>

                </React.Fragment>
            );
          })}
        </tr>
    </>
  )
}

export default AdditionalRow