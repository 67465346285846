import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

interface DefaultTableMessageProps {
    state?: "noData" | "noFilters" | "error" | "emptySearchResult" | "noFilter",
    tableLoading?: boolean
}

export const NoDataFoundIcon = () => (
    <svg width="58" height="51" viewBox="0 0 58 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.0001 18.0005V28.6672M29.0001 39.3339H29.0267M24.4401 4.29388L1.85339 42.0005C1.3877 42.807 1.1413 43.7213 1.13869 44.6526C1.13609 45.5839 1.37736 46.4996 1.83853 47.3086C2.29969 48.1177 2.96466 48.7919 3.76728 49.2642C4.5699 49.7364 5.48219 49.9903 6.41339 50.0006H51.5867C52.5179 49.9903 53.4302 49.7364 54.2328 49.2642C55.0355 48.7919 55.7004 48.1177 56.1616 47.3086C56.6228 46.4996 56.864 45.5839 56.8614 44.6526C56.8588 43.7213 56.6124 42.807 56.1467 42.0005L33.5601 4.29388C33.0847 3.51016 32.4153 2.8622 31.6166 2.4125C30.8178 1.96281 29.9167 1.72656 29.0001 1.72656C28.0834 1.72656 27.1823 1.96281 26.3835 2.4125C25.5848 2.8622 24.9154 3.51016 24.4401 4.29388Z" stroke="#677C9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const DefaultTableMessage = ({state, tableLoading = false}:DefaultTableMessageProps) => {
    return (
    <div className={'h-96 bg-slate-50 dark:bg-odin-dark-gray-darkest_bluish w-full justify-center items-center flex'}>
            {tableLoading ? <CircularProgress size="50px" sx={{color: '#0079ff'}}  /> : (
                <p className={'p-10 tablet-size:p-4 text-sm text-slate-500 dark:text-odin-dark-gray-bluish text-center'}>
                    {
                        state === "noData" && (
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <NoDataFoundIcon />
                                <h2 className='text-2xl font-semibold text-center'>No data found</h2>
                                <p className='text-center'>Please select a different date</p>
                            </div>
                        )
                    }
                    {
                        state === "noFilters" && (
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <NoDataFoundIcon />
                                <h2 className='text-2xl font-semibold text-center'>No filters found</h2>
                                <p className='text-center'>Select groups in sequence to view the report.</p>
                            </div>
                        )
                    }
                    {
                        state === "error" && (
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <NoDataFoundIcon />
                                <p className='text-center'>Something went wrong. Please try again later.</p>
                            </div>
                        )
                    }
                    {
                        state === "emptySearchResult" && (
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <NoDataFoundIcon />
                                <p className='text-center'>No results found for the search term. Please try again with a different search term.</p>
                            </div>
                        )
                    }
                    {
                        state === "noFilter" && (
                            <div className='flex flex-col gap-3 items-center justify-center'>
                                <NoDataFoundIcon />
                                <p className='text-center'>No filters found. Please select a filter to view the report.</p>
                            </div>
                        )
                    }
                </p>
            )}
    </div>
  )
}

export default DefaultTableMessage